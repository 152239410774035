import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from './Button';
import {
  fieldChange,
  handleDesignChange,
  loadGoogleAnalytics,
  getIconsFromStorage,
} from './actions';

const mapStateToProps = (state, ownProps) => {
  const layout = state.editor.data.layoutData[ownProps.match.params.appname];
  const {
    textColor,
    backgroundColor,
    borderRadius,
    enabled,
  } = ownProps.component.design;
  const { action, label } = ownProps.data;

  const design = {
    ...ownProps.component.design,
    buttonDesign: {
      enabled,
      textColor,
      backgroundColor,
      borderRadius,
    },
  };

  const link = {
    button: {
      label,
    },
    linkType: {
      page: action.page,
      web: action.web,
      phone: action.phone,
      email: action.email,
      selectedOption: action.selectedOption,
      auth: action.auth,
    },
    enabled: ownProps.data && ownProps.data.link && ownProps.data.link.enabled,
  };

  return {
    ...ownProps,
    googleAnalytics: state.app.googleAnalytics,
    link,
    component: {
      ...ownProps.component,
      design,
    },
    pages: layout
      ? layout.childRoutes.map(key => state.editor.data.alwaysData[key])
      : [],
    customIconsArray: state.editor.build.buttonComponent.customIconsArray || [],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onContentChange: (field, value) =>
    dispatch(
      fieldChange(
        `apps/${ownProps.match.params.appname}/draft/lazy_data/${ownProps.component.lazy_data}/${field}`,
        value,
      ),
    ),
  onLoadGoogleAnalytics: () =>
    dispatch(loadGoogleAnalytics(ownProps.match.params.appname)),
  onDesignChange: property => (type, value) =>
    dispatch(
      handleDesignChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        type,
        value,
        property,
      ),
    ),
  onGetIconUrls: () => dispatch(getIconsFromStorage()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Button));
