import React from 'react';

const SubscriptionPlans = () => {
  return (
    <svg
      className="icon htmlIcon customIcon"
      version="1.1"
      id="svg1566"
      width="682.66669"
      height="682.66669"
      viewBox="0 0 682.66669 682.66669"
    >
      <defs id="defs1570">
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1592">
          <path d="M 0,512 H 512 V 0 H 0 Z" id="path1590" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1616">
          <path d="M 0,512 H 512 V 0 H 0 Z" id="path1614" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1692">
          <path d="M 0,512 H 512 V 0 H 0 Z" id="path1690" />
        </clipPath>
      </defs>
      <g id="g1572" transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)">
        <g id="g1574" transform="translate(353.5322,316)">
          <path
            d="M 0,0 V -277.542 H -37.532"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1576"
          />
        </g>
        <g id="g1578" transform="translate(271,38.4575)">
          <path
            d="m 0,0 h -112.468 v 435.032 h 195 v -142.49"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1580"
          />
        </g>
        <g id="g1582" transform="translate(301,38.4575)">
          <path
            d="M 0,0 H -15"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1584"
          />
        </g>
        <g id="g1586">
          <g id="g1588" clip-path="url(#clipPath1592)">
            <g id="g1594" transform="translate(353.5322,83.4976)">
              <path
                d="M 0,0 H 150.957 V 344.8 H 0"
                style={{
                  fill: 'none',
                  stroke: 'currentColor',
                  strokeWidth: '15',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: '10',
                  strokeDasharray: 'none',
                  strokeOpacity: '1',
                }}
                id="path1596"
              />
            </g>
            <g id="g1598" transform="translate(158.5322,83.4976)">
              <path
                d="M 0,0 H -150.958 V 344.8 H 0"
                style={{
                  fill: 'none',
                  stroke: 'currentColor',
                  strokeWidth: '15',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: '10',
                  strokeDasharray: 'none',
                  strokeOpacity: '1',
                }}
                id="path1600"
              />
            </g>
          </g>
        </g>
        <g id="g1602" transform="translate(158.5322,413.3618)">
          <path
            d="M 0,0 H 195"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1604"
          />
        </g>
        <g id="g1606" transform="translate(61,368.0425)">
          <path
            d="M 0,0 H 97.532"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1608"
          />
        </g>
        <g id="g1610">
          <g id="g1612" clip-path="url(#clipPath1616)">
            <g id="g1618" transform="translate(7.5742,368.0425)">
              <path
                d="M 0,0 H 38.426"
                style={{
                  fill: 'none',
                  stroke: 'currentColor',
                  strokeWidth: '15',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: '10',
                  strokeDasharray: 'none',
                  strokeOpacity: '1',
                }}
                id="path1620"
              />
            </g>
            <g id="g1622" transform="translate(353.5322,368.0425)">
              <path
                d="M 0,0 H 150.957"
                style={{
                  fill: 'none',
                  stroke: 'currentColor',
                  strokeWidth: '15',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: '10',
                  strokeDasharray: 'none',
                  strokeOpacity: '1',
                }}
                id="path1624"
              />
            </g>
          </g>
        </g>
        <g id="g1626" transform="translate(188.7236,451)">
          <path
            d="M 0,0 V -15"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1628"
          />
        </g>
        <g id="g1630" transform="translate(218.5957,451)">
          <path
            d="M 0,0 V -15"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1632"
          />
        </g>
        <g id="g1634" transform="translate(38.0527,406)">
          <path
            d="M 0,0 V -15"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1636"
          />
        </g>
        <g id="g1638" transform="translate(384.0107,406)">
          <path
            d="M 0,0 V -15"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1640"
          />
        </g>
        <g id="g1642" transform="translate(196,218.415)">
          <path
            d="M 0,0 H 120"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1644"
          />
        </g>
        <g id="g1646" transform="translate(391,173.5269)">
          <path
            d="M 0,0 H 75"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1648"
          />
        </g>
        <g id="g1650" transform="translate(196,173.4687)">
          <path
            d="M 0,0 H 120"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1652"
          />
        </g>
        <g id="g1654" transform="translate(196,128.522)">
          <path
            d="M 0,0 H 120"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1656"
          />
        </g>
        <g id="g1658" transform="translate(196,83.4976)">
          <path
            d="M 0,0 H 15"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1660"
          />
        </g>
        <g id="g1662" transform="translate(226,83.4976)">
          <path
            d="M 0,0 H 15"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1664"
          />
        </g>
        <g id="g1666" transform="translate(391,128.522)">
          <path
            d="M 0,0 H 15"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1668"
          />
        </g>
        <g id="g1670" transform="translate(421,128.522)">
          <path
            d="M 0,0 H 15"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1672"
          />
        </g>
        <g id="g1674" transform="translate(46,173.5269)">
          <path
            d="M 0,0 H 75"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1676"
          />
        </g>
        <g id="g1678" transform="translate(46,128.522)">
          <path
            d="M 0,0 H 15"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1680"
          />
        </g>
        <g id="g1682" transform="translate(76,128.522)">
          <path
            d="M 0,0 H 15"
            style={{
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '15',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              strokeDasharray: 'none',
              strokeOpacity: '1',
            }}
            id="path1684"
          />
        </g>
        <g id="g1686">
          <g id="g1688" clip-path="url(#clipPath1692)">
            <g id="g1694" transform="translate(109.7637,308.4253)">
              <path
                d="m 0,0 h -29.988 c -8.217,0 -14.879,-6.674 -14.879,-14.908 0,-8.227 6.653,-14.9 14.864,-14.907 l 7.651,-0.008 c 8.211,-0.008 14.865,-6.68 14.865,-14.907 v -0.218 c 0,-8.208 -6.621,-14.871 -14.813,-14.908 l -30.227,-0.136"
                style={{
                  fill: 'none',
                  stroke: 'currentColor',
                  strokeWidth: '15',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: '10',
                  strokeDasharray: 'none',
                  strokeOpacity: '1',
                }}
                id="path1696"
              />
            </g>
            <g id="g1698" transform="translate(83.5,308.4253)">
              <path
                d="M 0,0 V 22.575"
                style={{
                  fill: 'none',
                  stroke: 'currentColor',
                  strokeWidth: '15',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: '10',
                  strokeDasharray: 'none',
                  strokeOpacity: '1',
                }}
                id="path1700"
              />
            </g>
            <g id="g1702" transform="translate(83.5,226)">
              <path
                d="M 0,0 V 22.429"
                style={{
                  fill: 'none',
                  stroke: 'currentColor',
                  strokeWidth: '15',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: '10',
                  strokeDasharray: 'none',
                  strokeOpacity: '1',
                }}
                id="path1704"
              />
            </g>
            <g id="g1706" transform="translate(282.2637,353.3511)">
              <path
                d="m 0,0 h -29.988 c -8.217,0 -14.879,-6.674 -14.879,-14.908 0,-8.228 6.653,-14.9 14.864,-14.908 l 7.651,-0.007 c 8.211,-0.008 14.865,-6.68 14.865,-14.908 v -0.218 c 0,-8.207 -6.621,-14.87 -14.813,-14.907 l -30.227,-0.136"
                style={{
                  fill: 'none',
                  stroke: 'currentColor',
                  strokeWidth: '15',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: '10',
                  strokeDasharray: 'none',
                  strokeOpacity: '1',
                }}
                id="path1708"
              />
            </g>
            <g id="g1710" transform="translate(256,353.3511)">
              <path
                d="M 0,0 V 22.574"
                style={{
                  fill: 'none',
                  stroke: 'currentColor',
                  strokeWidth: '15',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: '10',
                  strokeDasharray: 'none',
                  strokeOpacity: '1',
                }}
                id="path1712"
              />
            </g>
            <g id="g1714" transform="translate(256,270.9253)">
              <path
                d="M 0,0 V 22.43"
                style={{
                  fill: 'none',
                  stroke: 'currentColor',
                  strokeWidth: '15',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: '10',
                  strokeDasharray: 'none',
                  strokeOpacity: '1',
                }}
                id="path1716"
              />
            </g>
            <g id="g1718" transform="translate(455.0703,308.355)">
              <path
                d="m 0,0 h -29.988 c -8.217,0 -14.879,-6.674 -14.879,-14.908 0,-8.228 6.653,-14.9 14.864,-14.908 l 7.651,-0.007 c 8.211,-0.008 14.865,-6.68 14.865,-14.908 v -0.218 c 0,-8.207 -6.621,-14.871 -14.813,-14.907 l -30.227,-0.137"
                style={{
                  fill: 'none',
                  stroke: 'currentColor',
                  strokeWidth: '15',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: '10',
                  strokeDasharray: 'none',
                  strokeOpacity: '1',
                }}
                id="path1720"
              />
            </g>
            <g id="g1722" transform="translate(428.8066,308.355)">
              <path
                d="M 0,0 V 22.574"
                style={{
                  fill: 'none',
                  stroke: 'currentColor',
                  strokeWidth: '15',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: '10',
                  strokeDasharray: 'none',
                  strokeOpacity: '1',
                }}
                id="path1724"
              />
            </g>
            <g id="g1726" transform="translate(428.8066,225.9292)">
              <path
                d="M 0,0 V 22.43"
                style={{
                  fill: 'none',
                  stroke: 'currentColor',
                  strokeWidth: '15',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: '10',
                  strokeDasharray: 'none',
                  strokeOpacity: '1',
                }}
                id="path1728"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SubscriptionPlans;
