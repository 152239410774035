/* eslint-disable camelcase */
import _mapValues from 'lodash/mapValues';
import i18n from 'i18next';
import { validateField } from '../../../memberLogin/modules/memberBodies/validators';
import firebase from '../../../../firebase';
import { openSnackbar } from '../../../../app/modules/snackbar/actions';

export const TOGGLE_BLOG_MODAL = 'TOGGLE_BLOG_MODAL';
export const NEW_BLOG_FIELD_CHANGE = 'NEW_BLOG_FIELD_CHANGE';
export const SELECT_BLOG = 'SELECT_BLOG';
export const SELECT_ALL_BLOGS = 'SELECT_ALL_BLOGS';
export const DESELECT_ALL_BLOGS = 'DESELECT_ALL_BLOGS';
export const REQUEST_BLOG = 'REQUEST_BLOG';
export const FILTER_BLOG_DATA = 'FILTER_BLOG_DATA';
export const RECEIVE_BLOG = 'RECEIVE_BLOG';
export const ADD_NEW_BLOG = 'ADD_NEW_BLOG';
export const NEW_BLOG_ADDED = 'NEW_BLOG_ADDED';
export const OPEN_BLOG_DIALOG_UPDATE_DATA = 'OPEN_BLOG_DIALOG_UPDATE_DATA';
export const REQUEST_BLOG_CATEGORIES = 'REQUEST_BLOG_CATEGORIES';
export const RECEIVE_BLOG_CATEGORIES = 'RECEIVE_BLOG_CATEGORIES';
export const TOGGLE_BLOG_CATEGORIES_MODAL = 'TOGGLE_BLOG_CATEGORIES_MODAL';
export const TOGGLE_SAVE_BLOG = 'TOGGLE_SAVE_BLOG';

export const toggleSaveBlogFeature = value => ({
  type: TOGGLE_SAVE_BLOG,
  value,
});

export const toggleBlogModal = value => ({
  type: TOGGLE_BLOG_MODAL,
  value,
});

export const toggleBlogCategoriesModal = value => ({
  type: TOGGLE_BLOG_CATEGORIES_MODAL,
  value,
});

export const newBlogFieldChange = (prop, value, isValid) => ({
  type: NEW_BLOG_FIELD_CHANGE,
  prop,
  value,
  isValid,
});

export const selectBlog = key => ({
  type: SELECT_BLOG,
  key,
});

export const selectAllBlogs = () => ({
  type: SELECT_ALL_BLOGS,
});
export const deselectAllBlogs = () => ({
  type: DESELECT_ALL_BLOGS,
});

export const requestBlog = appname => ({
  type: REQUEST_BLOG,
  appname,
});

export const requestBlogCategories = appname => ({
  type: REQUEST_BLOG_CATEGORIES,
  appname,
});

export const filterBlogData = filter => dispatch =>
  dispatch({
    type: FILTER_BLOG_DATA,
    payload: filter,
  });

export const formFieldChange = (prop, value) => dispatch => {
  const isValid = value.length > 0 ? validateField[prop](value) : null;
  return dispatch(newBlogFieldChange(prop, value, isValid));
};

export const multipleValueFieldChange = (prop, value) => dispatch =>
  dispatch(newBlogFieldChange(prop, value, true));

export const getBlogs = appname => dispatch => {
  dispatch(requestBlog(appname));
  firebase
    .database()
    .ref(`blog/${appname}/blogs`)
    .on('value', snap => {
      dispatch(requestBlog(appname));
      if (snap.exists()) {
        dispatch({
          type: RECEIVE_BLOG,
          value: snap.val(),
        });
      } else {
        dispatch({
          type: RECEIVE_BLOG,
          value: [],
        });
      }
    });
};

export const getBlogCategories = appname => dispatch => {
  firebase
    .database()
    .ref(`blog/${appname}/blog_categories`)
    .once('value', snap => {
      dispatch(requestBlogCategories(appname));

      if (snap.exists()) {
        dispatch({
          type: RECEIVE_BLOG_CATEGORIES,
          value: snap.val(),
        });
      } else {
        dispatch({
          type: RECEIVE_BLOG_CATEGORIES,
          value: [],
        });
      }
    });
};

export const addBlog = appname => (dispatch, getState) => {
  const {
    title,
    published,
    categories,
    content,
    cover_image,
    isUpdate,
    rowId,
  } = getState().engage.blog;

  const blogPayload = {
    title: title.value,
    published: published.value,
    categories: categories.value,
    content: content.value,
    cover_image: cover_image.value,
    date: Date.now(),
  };

  let isError = false;

  console.log(blogPayload);
  if (!blogPayload.title.length) {
    isError = true;
    dispatch(newBlogFieldChange('title', '', false));
  }

  if (isError) {
    return dispatch(
      openSnackbar(i18n.t(`Please complete all required fields.`)),
    );
  }

  dispatch({ type: ADD_NEW_BLOG });
  if (isUpdate) {
    return firebase
      .database()
      .ref(`blog/${appname}/blogs/${rowId}`)
      .update(blogPayload)
      .then(() => {
        const newBlogId = rowId;
        dispatch({
          type: NEW_BLOG_ADDED,
          key: newBlogId,
          data: blogPayload,
        });
        // reset temporary image
        firebase
          .database()
          .ref(`blog/${appname}/tempImages`)
          .set(null);
      });
  }
  return firebase
    .database()
    .ref(`blog/${appname}/blogs`)
    .push(blogPayload)
    .then(ref => {
      const newBlogId = ref.key;
      dispatch({
        type: NEW_BLOG_ADDED,
        key: newBlogId,
        data: blogPayload,
      });
      // reset temporary image
      firebase
        .database()
        .ref(`blog/${appname}/tempImages`)
        .set(null);
    });
};

export const addBlogCategory = (appname, category) => (dispatch, getState) => {
  const blogCategories = { ...getState().engage.blog.blogCategories };

  const categoryKey = category
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\s\/-]/g, '') // Remove special characters except spaces, slashes, and hyphens
    .replace(/[\s/]+/g, '_'); // Replace spaces and slashes with underscores

  return firebase
    .database()
    .ref(`blog/${appname}/blog_categories/${categoryKey}`)
    .set(category)
    .then(ref => {
      blogCategories[categoryKey] = category;
      dispatch({
        type: RECEIVE_BLOG_CATEGORIES,
        value: blogCategories,
      });
    });
};

export const removeBlogCategory = (appname, categoryKey) => (
  dispatch,
  getState,
) => {
  const blogCategories = { ...getState().engage.blog.blogCategories };

  return firebase
    .database()
    .ref(`blog/${appname}/blog_categories/${categoryKey}`)
    .set(null)
    .then(() => {
      delete blogCategories[categoryKey];
      dispatch({
        type: RECEIVE_BLOG_CATEGORIES,
        value: blogCategories,
      });
    });
};

export const deleteBlog = (appname, blogId) => dispatch => {
  if (blogId !== 'default') {
    firebase
      .database()
      .ref(`blog/${appname}/blogs/${blogId}`)
      .remove();
  }
};

export const deleteBlogs = appname => (dispatch, getState) => {
  const { selected } = getState().engage.blog;
  Promise.all(selected.map(key => deleteBlog(appname, key)));
};

export const updateBlog = (rowId, data) => dispatch => {
  dispatch({
    type: OPEN_BLOG_DIALOG_UPDATE_DATA,
    key: rowId,
    data,
  });
};

export const fetchBlogImage = dbRef => async dispatch => {
  const ref = firebase.database().ref(`${dbRef}`);
  return ref.once('value', snapshot => {
    if (snapshot.exists()) {
      const temp = snapshot.val();
      return dispatch(newBlogFieldChange('cover_image', temp, true));
    }
    // return dispatch(newRegionalPerformanceFieldChange('images', [], true));
  });
};

export const toggleSaveBlog = (appname, value) => dispatch => {
  if (value) {
    Promise.all([
      firebase
        .database()
        .ref(`app_groups/${appname}/groups`)
        .once('value'),
      firebase
        .database()
        .ref(`apps/${appname}/draft/always_data/auth`)
        .set({
          type: 'auth',
          authType: 'multi',
        }),
      firebase
        .database()
        .ref(`blog/${appname}/blog_config/saveBlog`)
        .set(value),
    ]).then(([snap]) => {
      if (!snap.exists()) {
        firebase
          .database()
          .ref(`apps/${appname}/draft/lazy_data`)
          .once('value')
          .then(lazyData => {
            firebase
              .database()
              .ref(`app_groups/${appname}`)
              .set({
                groups: {
                  default: {
                    name: 'My Default Group',
                    default: true,
                    date: Date.now(),
                    access: {
                      // provide seed key, so access node exists
                      // ...and because we aren't handling this correctly in other places at the moment
                      someKey: true,
                    },
                  },
                },
                not_private: _mapValues(lazyData.val(), () => true),
              });
          });
      }
    });
    dispatch({
      type: 'TOGGLE_AUTH',
      appname,
      toggle: value,
      authType: 'multi',
    });
  } else {
    firebase
      .database()
      .ref(`blog/${appname}/blog_config/saveBlog`)
      .set(value);
  }
  dispatch({
    type: TOGGLE_SAVE_BLOG,
    value,
  });
};

export const getBlogSaveBlogConfig = appname => dispatch => {
  firebase
    .database()
    .ref(`blog/${appname}/blog_config/saveBlog`)
    .once('value', snap => {
      if (snap.exists()) {
        dispatch({
          type: TOGGLE_SAVE_BLOG,
          value: snap.val(),
        });
      } else {
        dispatch({
          type: TOGGLE_SAVE_BLOG,
          value: false,
        });
      }
    });
};
