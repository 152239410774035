import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _mapValues from 'lodash/mapValues';
import IconButton from 'material-ui/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Cancel from 'material-ui/svg-icons/navigation/cancel';
import moment from 'moment';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TreeItem from '@material-ui/lab/TreeItem';
import ActionMenu from 'app/components/actionMenu';
import ResultsTable from 'app/components/resultsTable';
import { Trans, withTranslation } from 'react-i18next';
import TryAgain from 'app/components/tryAgain';
import Header from 'app/components/header';
import { formatDate, downloadCSV } from 'utils';
import TableCheckbox from '../../components/tableCheckbox';
import HistoryDetails from './historyDetails';
import './styles.css';
import themeStyles from '../../../../_export.scss';

const isCheckboxDisabled = (error, pending) => error && !pending;

class History extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object,
    items: PropTypes.array.isRequired,
    error: PropTypes.string,
    filter: PropTypes.string,
    getPushHistory: PropTypes.func.isRequired,
    onChangeSelected: PropTypes.func.isRequired,
    filterPushHistory: PropTypes.func.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onSelectAllResponses: PropTypes.func.isRequired,
    onDeselectAllResponses: PropTypes.func.isRequired,
    selected: PropTypes.array,
    selectAll: PropTypes.bool,
    onSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    colSorted: PropTypes.string.isRequired,
    onCancelPush: PropTypes.func.isRequired,
    onOpenHistoryDetail: PropTypes.func.isRequired,
    onFetchNotificationData: PropTypes.func.isRequired,
    filteredList: PropTypes.array,
    getGroups: PropTypes.func.isRequired,
    groups: PropTypes.object.isRequired,
    onGetCountries: PropTypes.func.isRequired,
    push: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
    filteredChunks: {},
    selectAll: false,
    selected: [],
  };

  componentDidMount() {
    this.props.getPushHistory();
    this.props.getGroups();
    const getCountries = async () => {
      await this.props.onGetCountries();
    };
    getCountries();
  }

  getCategoryClass = value => {
    switch (value) {
      case 'All':
      default:
        return 'all';
    }
  };

  sentTo = item => {
    const countries = this.props.push.filteredCountries;
    if (item.filteredList) {
      return item.filteredList.join('; ');
    } else if (item.groupsFiltered) {
      return item.groupsFilteredList
        .map(
          data =>
            this.props.groups &&
            this.props.groups[data] &&
            this.props.groups[data].name,
        )
        .join('; ');
    } else if (item.locationFiltered) {
      return (
        countries &&
        countries.length > 0 &&
        countries.find(
          country => country.value === item.locationFilteredList[0],
        ).name
      );
    }
    return 'EVERYONE';
  };

  getCsvData = (filteredChunks, selected) => {
    const { t } = this.props;
    const csvColumns = [
      t('Date'),
      t('Scheduled'),
      t('Title'),
      t('Message'),
      t('Sent to'),
    ];
    let data = `"${csvColumns.join('","')}"\n`;

    const newObject = Object.entries(filteredChunks).filter(([key, value]) =>
      selected.includes(key),
    );
    const newFilteredChunks = Object.fromEntries(newObject);

    Object.values(newFilteredChunks).forEach(item => {
      data += `"${formatDate(item.date)}","${
        !item.cancelled && moment(item.date).isAfter()
          ? t('Scheduled')
          : !item.cancelled && !moment(item.date).isAfter()
          ? t('Already sent')
          : t('Not Scheduled')
      }","${item.title}","${item.message}", ${
        this.sentTo(item)
        // item.filteredList ? item.filteredList.join('; ') : 'Everyone'
      }`;
      data += '\n';
    });

    return data;
  };

  subHeaderContent = () => {
    const { items } = this.props;

    return (
      <span>
        <Trans>Total Notifications Sent</Trans>: <b>{items.length || 0}</b>
      </span>
    );
  };

  handleOpenHistoryDetails = key => {
    this.props.onFetchNotificationData(
      this.props.filteredChunks[key].messageId,
    );
    this.props.onOpenHistoryDetail(key);
  };

  render() {
    const {
      pending,
      items,
      filteredChunks,
      error,
      getPushHistory,
      filterPushHistory,
      onCancelPush,
      colSorted,
      order,
      onSort,
      filter,
      selectAll,
      selected,
      chunks,
      onSelectAllResponses,
      onDeselectAllResponses,
      onChangeSelected,
      onDeleteSelected,
      filteredList,
      groups,
      push,
      t,
    } = this.props;

    const countries = push.filteredCountries;

    const suffixClass = items.length < 4 ? 'fix-left' : 'selected';

    const tableCols = [
      {
        id: 'date',
        header: <Trans>Date Created</Trans>,
        classNames: 'col-xs-2 created-at',
        containerElement: ({ value }) => (
          <span>{value && formatDate(value)}</span>
        ),
      },
      {
        id: 'isScheduled',
        header: t('Scheduled'),
        classNames: 'col-xs-2',
        containerElement: ({ value, rowId }) => (
          <span>
            {filteredChunks[rowId] && filteredChunks[rowId].cancelled ? (
              t('Cancelled')
            ) : moment(filteredChunks[rowId].date).isAfter() ? (
              <span className="scheduled-button" title={t('Cancel')}>
                <Trans>Scheduled</Trans>
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    onCancelPush(rowId);
                  }}
                >
                  <Cancel />
                </IconButton>
              </span>
            ) : (
              t('Already sent')
            )}
          </span>
        ),
      },
      {
        id: 'sentTo',
        header: t('Sent To'),
        classNames: 'col-xs-2',
        containerElement: ({ value, rowId }) => (
          <span className="sentToTable">
            {filteredChunks[rowId] && filteredChunks[rowId].filteredList ? (
              <TreeView
                className=""
                defaultCollapseIcon={<ExpandLessIcon />}
                defaultExpandIcon={<ExpandMoreIcon />}
                onClick={e => e.stopPropagation(e)}
              >
                <TreeItem nodeId="1" label={t('Users')}>
                  {filteredChunks[rowId].filteredList.map((data, index) => (
                    <h3>{data}</h3>
                  ))}
                </TreeItem>
              </TreeView>
            ) : filteredChunks[rowId] &&
              filteredChunks[rowId].groupsFiltered ? (
              <TreeView
                className=""
                defaultCollapseIcon={<ExpandLessIcon />}
                defaultExpandIcon={<ExpandMoreIcon />}
                onClick={e => e.stopPropagation(e)}
              >
                <TreeItem nodeId="1" label={t('Groups')}>
                  {filteredChunks[rowId].groupsFilteredList &&
                    filteredChunks[rowId].groupsFilteredList.map(data => (
                      <h3>{groups && groups[data] && groups[data].name}</h3>
                    ))}
                </TreeItem>
              </TreeView>
            ) : filteredChunks[rowId] &&
              filteredChunks[rowId].locationFiltered ? (
              <TreeView
                className=""
                defaultCollapseIcon={<ExpandLessIcon />}
                defaultExpandIcon={<ExpandMoreIcon />}
                onClick={e => e.stopPropagation(e)}
              >
                <TreeItem nodeId="1" label={t('Locations')}>
                  <h3>
                    {countries &&
                      countries.length > 0 &&
                      countries.find(
                        country =>
                          country.value ===
                          filteredChunks[rowId].locationFilteredList[0],
                      ).name}
                  </h3>
                </TreeItem>
              </TreeView>
            ) : (
              <span>
                <Trans>EVERYONE</Trans>
              </span>
            )}
          </span>
        ),
      },
      {
        id: 'title',
        header: t('Title'),
        classNames: 'col-xs-2',
        containerElement: ({ value }) => (
          <span
            style={{
              textAlign: 'center',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              maxWidth: '200px',
            }}
          >
            {value}
          </span>
        ),
      },
      {
        id: 'message',
        header: t('Message'),
        classNames: 'col-xs-2',
        containerElement: ({ value }) => (
          <span
            style={{
              textAlign: 'center',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              maxWidth: '200px',
            }}
          >
            {value}
          </span>
        ),
      },
      {
        id: 'visible',
        header: t('Visible'),
        classNames: 'col-xs-1',
        containerElement: ({ value, rowId }) =>
          value === false ? (
            <span>
              <Trans>No</Trans>
            </span>
          ) : (
            <span>
              <Trans>Yes</Trans>
            </span>
          ),
      },
      {
        id: 'selected',
        classNames: `col-xs-1 ${suffixClass}`,
        header: (
          <div style={{ display: 'flex' }}>
            <Checkbox
              checked={selectAll}
              onChange={(e, isChecked) => {
                isChecked ? onSelectAllResponses() : onDeselectAllResponses();
              }}
              disabled={isCheckboxDisabled(error, pending)}
              style={{ marginLeft: -2, color: themeStyles.primaryColor }}
              disableRipple
            />
            <ActionMenu
              selected={selected}
              onVisibilitySelected={() => onChangeSelected(selected)}
              onDownloadSelected={() =>
                downloadCSV(
                  this.getCsvData(filteredChunks, selected),
                  'push-notification-history',
                )
              }
              onDeleteSelected={() => onDeleteSelected(selected)}
              text={t('push notifications')}
            />
          </div>
        ),
        containerElement: ({ value, rowId }) => (
          <>
            <TableCheckbox
              value={value}
              onSelect={() => this.props.onSelect(rowId)}
            />
            <ActionMenu
              selected={[rowId]}
              onVisibilitySelected={() => onChangeSelected([rowId])}
              onDownloadSelected={() =>
                downloadCSV(
                  this.getCsvData(filteredChunks, [rowId]),
                  'push-notification-history',
                )
              }
              onDeleteSelected={() => onDeleteSelected([rowId])}
              onEditSelected={() => this.handleOpenHistoryDetails(rowId)}
              text={t('push notification')}
            />
          </>
        ),
      },
    ];

    const tableRows = _mapValues(filteredChunks, (chunk, key) => ({
      ...chunk,
    }));

    const body = error ? (
      <TryAgain
        message={t('Oops, something went wrong...')}
        onRetry={getPushHistory}
      />
    ) : (
      <ResultsTable
        items={items}
        chunks={tableRows}
        loading={pending}
        error={error || (items.length === 0 ? t('No items') : null)}
        pending={pending}
        columns={tableCols}
        emptyMessage={t("You've not sent any Push Notifications yet.")}
        sortTable={cols => onSort(cols)}
        order={order}
        colSorted={colSorted}
        colsToSort={['date']}
        onRowTouchTap={this.handleOpenHistoryDetails}
      />
    );

    const containerHeight = { height: 'calc(100% - 168px)' };

    return (
      <section style={containerHeight}>
        <Header
          title={this.subHeaderContent()}
          onChange={filterPushHistory}
          filter={filter}
          selectAll={selectAll}
          onSelectAllResponses={onSelectAllResponses}
          onDeselectAllResponses={onDeselectAllResponses}
          chunks={chunks}
        />
        {body}
        {items && filteredChunks && (
          <HistoryDetails groups={groups} countries={countries} />
        )}
      </section>
    );
  }
}

export default withTranslation()(History);
