import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';
import Toggle from 'material-ui/Toggle';
import Checkbox from 'material-ui/Checkbox';
import { withTranslation } from 'react-i18next';
import ColorPicker from '../../../../../components/colorPicker';
import Container from '../../../../../../../app/components/container';
import TextField from '../../../../../../../app/components/textField';
import TextArea from '../../../../../../../app/components/textArea';
import SelectField from '../../../../../../../app/components/selectField';
import CircularValidation from '../../../../../../../app/components/circularValidation';
import ButtonDesignMenu from '../../../../../components/buttonDesignMenu';
import DesignToggle from '../../../../../components/designToggle';
import { Tabs, Tab } from '../../../../../components/tabs';
import DraggableListWrapper from '../../../../../components/draggableListWrapper';
import ComponentItem from '../../../../../components/componentItem';
import DeleteError from '../../../../../../editor/components/deleteError';
import TitleSetter from '../../../../../../editor/components/titleSetter';
import ButtonLabelSetter from '../../../../../../editor/components/buttonLabelSetter';
import AutoFillEmail from '../../../../../../editor/components/autoFillEmail';
import SectionHeader from '../../../../../../editor/components/sectionHeader';
import { capitaliseString } from '../../../../../../../utils';
import { LOCATION_FEATURE_ENABLED_APPS } from '../../../../../../../config';
import { validateField } from './validator';
import './styles.css';
import themeStyles from '../../../../../../../_export.scss';
import TooltipElement from '../../../../../../../app/components/tooltipElement';

let types = [
  'text',
  'email',
  'checkbox',
  'file',
  'number',
  'phone number',
  'dropdown',
  'location',
];

const minFields = 1;
// this never changes, so mapping once on runtime is fine...
let options = types.map(type => (
  <MenuItem key={type} value={type} primaryText={capitaliseString(type)} />
));

const Div = styled.div`
  width: 100%;
  padding: 15px 0px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 15px;

  .tooltip {
    > span > div {
      line-height: 15px !important;
      width: 258px !important;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      top: -48px !important;
      > span {
        white-space: break-spaces !important;
      }
    }
  }
`;

const ErrorWrapper = styled.div`
  width: 100%;
  padding-left: 41px;
  padding-bottom: 20px;

  > span {
    color: #ff0000;
    font-size: 12px;
  }
`;
class Form extends Component {
  static propTypes = {
    data: PropTypes.shape({
      title: PropTypes.string,
      displayTitle: PropTypes.bool,
      caption: PropTypes.string,
      fields: PropTypes.shape({
        order: PropTypes.arrayOf(PropTypes.string),
        chunks: PropTypes.object,
      }),
      buttonText: PropTypes.string,
      autoFillEmail: PropTypes.bool,
    }).isRequired,
    component: PropTypes.object.isRequired,
    onFieldChange: PropTypes.func.isRequired,
    onButtonDesignChange: PropTypes.func.isRequired,
    onAddField: PropTypes.func.isRequired,
    onRemoveField: PropTypes.func.isRequired,
    onDesignToggle: PropTypes.func.isRequired,
    onRearrangeField: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onFormDesignChange: PropTypes.func.isRequired,
    duplicateItem: PropTypes.func.isRequired,
  };

  static defaultProps = {
    title: '',
    displayTitle: true,
    caption: '',
    fields: {
      order: [],
      chunks: {},
    },
  };

  state = {
    deleteObjectError: false,
    thankYouMessage: '',
    errorMessage: null,
    thankYouLength: 10,
  };

  async componentDidMount() {
    await this.props.onLoadGoogleAnalytics();
    const locationType = 'location';
    let index = types.indexOf('location');

    if (
      LOCATION_FEATURE_ENABLED_APPS.indexOf(this.props.match.params.appname) >
      -1
    ) {
      if (index === -1) {
        types.push('location');
        options.push(
          <MenuItem
            key={locationType}
            value={locationType}
            primaryText={capitaliseString(locationType)}
          />,
        );
      }
    } else {
      if (index > -1) {
        options.pop(index);
        types.pop(index);
      }
    }
  }

  onToggleTitleDisplay = isToggled =>
    this.props.onFieldChange('displayTitle', isToggled);

  onToggleDisplay = (field, isToggled) =>
    this.props.onFieldChange(field, isToggled);

  onChangeTitle = value => this.props.onFieldChange('title', value);
  onChangeButtonText = value => this.props.onFieldChange('buttonText', value);
  onChangeAutoFillEmail = value =>
    this.props.onFieldChange('autoFillEmail', value);
  onChangeField = (field, value) => this.props.onFieldChange(field, value);

  getItemHref = key => ({
    pathname: `/${this.props.match.params.appname}/editor/build/item`,
    search: `${this.props.location.search}&item=${key}`,
  });

  handleMove = (el, target, source, sibling) =>
    sibling
      ? this.props.onRearrangeField(el.id, sibling.id)
      : this.props.onRearrangeField(el.id, null);

  handleNavigate = key => () => {
    const href = this.getItemHref(key);

    return this.props.history.push(href);
  };

  handleColorChange = prop => value => {
    const rgba = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    this.props.onFormDesignChange(prop, rgba);
  };

  handleRemove = (key, index) => {
    const { data } = this.props;

    if (
      data &&
      data.fields &&
      data.fields.chunks &&
      Object.keys(data.fields.chunks).length <= minFields
    ) {
      return this.state.deleteObjectError
        ? null
        : this.setState({ deleteObjectError: true });
    }
    return this.props.onRemoveField(index, key);
  };

  handleAddField = value => {
    this.setState({ deleteObjectError: false });
    this.props
      .onAddField(value)
      .then(items => this.handleNavigate(items.pop())());
  };

  formHasEmailField = fields =>
    Object.keys(fields.chunks).filter(
      key => fields.chunks[key].type === 'email',
    ).length > 0;

  renderItems = () => {
    const { fields } = this.props.data;
    const { duplicateItem } = this.props;
    return fields.order.map((key, i) => (
      <ComponentItem
        id={key}
        key={key}
        label={`${fields.chunks[key].type.charAt(0).toUpperCase() +
          fields.chunks[key].type.slice(1)}${
          fields.chunks[key].label ? ` - ${fields.chunks[key].label}` : ''
        }`}
        onClick={this.handleNavigate(key)}
        onDelete={() => this.handleRemove(key, i)}
        onDuplicate={() =>
          duplicateItem(fields.order.length - 1, fields.chunks[key])
        }
      />
    ));
  };

  handleChangeLinkSection = (prop, value) => {
    const propTransformed = prop
      .replace(/link\/linkType\//g, 'action/')
      .replace(/link\/button\//g, '');
    this.props.onFieldChange(propTransformed, value);
  };

  render() {
    const {
      onButtonDesignChange,
      component,
      onDesignToggle,
      onFieldChange,
      match,
      pages,
      link,
      t,
    } = this.props;

    const {
      title,
      displayTitle,
      displayNewEmail,
      caption,
      fields,
      buttonText,
      autoFillEmail,
      newEmailId,
      sendCopy,
      displayCustomThankyouMessage,
      newCustomThankyouMessage,
      viewFormEntries,
    } = this.props.data;

    return (
      <React.Fragment>
        <Tabs>
          <Tab label={t('content')}>
            <Container>
              <TitleSetter
                displayTitle={displayTitle !== undefined ? displayTitle : true}
                onToggleTitleDisplay={this.onToggleTitleDisplay}
                onChangeTitle={this.onChangeTitle}
                title={title}
                typeOfComponent="form"
                isValid={title !== '' && title !== undefined}
                required
                onChangeSubmitButtonText={this.onChangeButtonText}
                submitButtonText={buttonText}
                match={match}
              />
              <TextArea
                id="form-caption"
                label={t('Enter some instructions (optional)')}
                value={caption}
                onChange={value => onFieldChange('caption', value)}
              />
              <Divider
                style={{
                  width: '100%',
                  height: '2px',
                }}
              />
              <SectionHeader
                title={t('New Email Address')}
                className="section-header"
                actions={
                  <Toggle
                    toggled={displayNewEmail}
                    onToggle={(e, isToggled) =>
                      this.onToggleDisplay('displayNewEmail', isToggled)
                    }
                    trackSwitchedStyle={{
                      backgroundColor: themeStyles.primaryColorLight,
                    }}
                    thumbSwitchedStyle={{
                      backgroundColor: themeStyles.primaryColor,
                    }}
                  />
                }
              />
              <TextField
                value={newEmailId}
                onChange={value => this.onChangeField('newEmailId', value)}
                placeholder={t('New Email Address')}
                disabled={!displayNewEmail}
                rightInputAction={
                  displayNewEmail && (
                    <CircularValidation
                      isValid={
                        newEmailId &&
                        newEmailId.length > 0 &&
                        validateField['email'](newEmailId)
                      }
                      defaultMsg={t('New email is required')}
                      errorMsg={t('New email is required in correct format')}
                      showDefault={!newEmailId || newEmailId.length === 0}
                    />
                  )
                }
              />
              <Checkbox
                id="auto-fill-email"
                label={t("Send a copy of Form entry to admin's email address")}
                style={{
                  paddingLeft: 18,
                  paddingRight: 18,
                  marginTop: 18,
                  marginBottom: 18,
                }}
                labelStyle={{ fontSize: 14 }}
                disabled={!displayNewEmail}
                checked={sendCopy}
                onCheck={(e, checked) =>
                  this.onChangeField('sendCopy', checked)
                }
              />
              <Wrapper>
                <Checkbox
                  id="view-form-entries"
                  label={t('Allow users to view the Form entries.')}
                  style={{
                    paddingLeft: 18,
                    paddingRight: 18,
                    marginTop: 18,
                    marginBottom: 18,
                  }}
                  labelStyle={{ fontSize: 14 }}
                  checked={viewFormEntries}
                  onCheck={(e, checked) =>
                    this.onChangeField('viewFormEntries', checked)
                  }
                />
                <div className="tooltip">
                  <TooltipElement
                    tooltipHorizontal="right"
                    tooltip={
                      'The user will be able to view a list of their form submissions in the "Form Entries" section in the app.'
                    }
                    iconStyle={{ marginLeft: 24, color: '#261ed5' }}
                    tooltipType="info"
                  />
                </div>
              </Wrapper>
              <Divider
                style={{
                  width: '100%',
                  height: '2px',
                }}
              />

              {/* CUSTOM THANK YOU MESSAGE */}

              <SectionHeader
                title={`${t('Custom Thank you Message')}`}
                className="section-header"
                actions={
                  <Toggle
                    toggled={displayCustomThankyouMessage}
                    onToggle={(e, isToggled) =>
                      this.onToggleDisplay(
                        'displayCustomThankyouMessage',
                        isToggled,
                      )
                    }
                    trackSwitchedStyle={{
                      backgroundColor: themeStyles.primaryColorLight,
                    }}
                    thumbSwitchedStyle={{
                      backgroundColor: themeStyles.primaryColor,
                    }}
                  />
                }
              />
              <TextField
                value={newCustomThankyouMessage}
                onChange={value =>
                  this.onChangeField('newCustomThankyouMessage', value)
                }
                placeholder={t('Thank you Message')}
                disabled={!displayCustomThankyouMessage}
                style={{ marginBottom: '20px' }}
              />
              <ErrorWrapper>
                <span>
                  {newCustomThankyouMessage &&
                    newCustomThankyouMessage.length > 40 &&
                    `Message must not be more than 40 characters.`}
                </span>
              </ErrorWrapper>

              <Divider
                style={{
                  width: '100%',
                  height: '2px',
                }}
              />

              {/* END HERE */}
              <SectionHeader
                title={t('Fields')}
                tooltip={t('Add the form fields for users to complete.')}
              />
              <SelectField
                label={t('Input Type')}
                options={options}
                onChange={(i, value) => this.handleAddField(value)}
              />

              {fields && (
                <div style={{ width: '100%' }}>
                  <DraggableListWrapper
                    items={this.renderItems()}
                    onPositionChange={this.handleMove}
                  />
                  {this.state.deleteObjectError && (
                    <DeleteError text={t('form fields')} number={minFields} />
                  )}
                </div>
              )}
              <ButtonLabelSetter
                onChange={this.onChangeButtonText}
                label={buttonText || t('Submit')}
                link={link}
                pages={pages}
                handleChangeLinkSection={this.handleChangeLinkSection}
                noButtonLink={true}
                googleAnalytics={this.props.googleAnalytics}
                gaEnabled={this.props.data.gaEnabled}
                gaCategory={this.props.data.gaCategory}
                gaAction={this.props.data.gaAction}
              />
              {this.formHasEmailField(fields) && (
                <AutoFillEmail
                  onChange={this.onChangeAutoFillEmail}
                  isChecked={autoFillEmail}
                />
              )}
            </Container>
          </Tab>
          <Tab label={t('design')}>
            <Container>
              <DesignToggle
                component={component}
                onDesignChange={(e, isToggled) => onDesignToggle(isToggled)}
              />

              <Div>
                <SectionHeader
                  title={t('Form Fields')}
                  tooltip=""
                  enabled={component.design.enabled}
                />
                <ColorPicker
                  label={t('Text Color')}
                  color={component.design.fieldTextColor}
                  height={22}
                  disabled={!component.design.enabled}
                  onChange={this.handleColorChange('fieldTextColor')}
                />
              </Div>

              <SectionHeader
                title={t('Button Design')}
                tooltip=""
                enabled={component.design.enabled}
              />
              <ButtonDesignMenu
                component={component}
                onDesignChange={onButtonDesignChange}
              />
            </Container>
          </Tab>
          <Tab
            label={t('layout')}
            disabled
            style={{
              color: '#d9d9d9',
            }}
          />
        </Tabs>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Form);
