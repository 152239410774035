import { connect } from 'react-redux';
import { get } from 'lodash';

import Form from './Form';

import {
  fieldChange,
  addField,
  launchRemoveDialog,
  rearrangeField,
  designChange,
  loadGoogleAnalytics,
  duplicateItem,
} from './actions';

const mapStateToProps = (state, ownProps) => {
  const layout = state.editor.data.layoutData[ownProps.match.params.appname];
  const { action } = ownProps.data;
  const link = {
    button: {
      label: get(ownProps.data, 'buttonText', ''),
    },
    linkType: {
      page: get(action, 'page', ''),
      web: get(action, 'web', ''),
      phone: get(action, 'phone', ''),
      email: get(action, 'email', ''),
      selectedOption: get(action, 'selectedOption', ''),
    },
    enabled: get(ownProps, 'data.link.enabled', false),
  };

  return {
    ...ownProps,
    googleAnalytics: state.app.googleAnalytics,
    pages: layout
      ? layout.childRoutes.map(key => state.editor.data.alwaysData[key])
      : [],
    link,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFieldChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onLoadGoogleAnalytics: () =>
    dispatch(loadGoogleAnalytics(ownProps.match.params.appname)),
  onAddField: value =>
    dispatch(
      addField(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        value,
      ),
    ),
  onRemoveField: (index, key) =>
    dispatch(
      launchRemoveDialog(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        index,
        key,
      ),
    ),
  duplicateItem: (lastIndex, data) =>
    dispatch(
      duplicateItem(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        lastIndex,
        data,
      ),
    ),
  onRearrangeField: (id, siblingId) =>
    dispatch(
      rearrangeField(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        id,
        siblingId,
      ),
    ),
  onButtonDesignChange: (type, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        `buttonDesign/${type}`,
        value,
      ),
    ),
  onDesignToggle: value =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        'enabled',
        value,
      ),
    ),
  onFormDesignChange: (type, value) =>
    dispatch(
      designChange(
        ownProps.match.params.appname,
        ownProps.component.key,
        type,
        value,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
