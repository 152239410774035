import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';
import XLSX from 'xlsx';
import { withTranslation, Trans } from 'react-i18next';
import Star from 'material-ui/svg-icons/toggle/star';
import StarBorder from 'material-ui/svg-icons/toggle/star-border';
import Checkbox from '@material-ui/core/Checkbox';

import ResultsTable from 'app/components/resultsTable';
import Header from 'app/components/header';
import ActionMenu from 'app/components/actionMenu';
import { formatDate, downloadCSV } from 'utils';
import TableCheckbox from '../../components/tableCheckbox';
import RatingDetails from './ratingDetails';

import './styles.css';
import themeStyles from '../../../../_export.scss';
import { objectToArray } from '../../../../utils/object';

const isCheckboxDisabled = (error, pending) => error && !pending;

class Rating extends Component {
  static propTypes = {
    pending: PropTypes.bool.isRequired,
    filteredChunks: PropTypes.object,
    filteredRatings: PropTypes.array,
    items: PropTypes.array.isRequired,
    chunks: PropTypes.object,
    error: PropTypes.string,
    filter: PropTypes.string,
    watchRatingData: PropTypes.func.isRequired,
    removeRatingDataWatcher: PropTypes.func.isRequired,
    getAllRatings: PropTypes.func.isRequired,
    onFilterRatingData: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onSelectAllResponses: PropTypes.func.isRequired,
    onDeselectAllResponses: PropTypes.func.isRequired,
    onOpenRatingDetail: PropTypes.func.isRequired,
    selectAll: PropTypes.bool,
    ratings: PropTypes.array.isRequired,
    onDeleteSelected: PropTypes.func.isRequired,
    selected: PropTypes.array.isRequired,
    onSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    colSorted: PropTypes.string.isRequired,
    appname: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    subscription: PropTypes.object.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: '',
    filteredChunks: {},
    filteredRatings: {},
    chunks: {},
    selectAll: false,
  };

  componentDidMount() {
    this.props.watchRatingData();
    this.props.getAllRatings();
  }

  componentWillUnmount() {
    this.props.removeRatingDataWatcher();
  }

  getSelectAllCheckbox = () => {
    const {
      onSelectAllResponses,
      onDeselectAllResponses,
      error,
      pending,
      selectAll,
    } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e, isChecked) => {
            isChecked ? onSelectAllResponses() : onDeselectAllResponses();
          }}
          disabled={isCheckboxDisabled(error, pending)}
          style={{ marginLeft: -2, color: themeStyles.primaryColor }}
          disableRipple
        />
        {this.getActionMenu()}
      </div>
    );
  };

  getTotalRatingsCount = ratings =>
    ratings.totals ? Object.values(ratings.totals).reduce((a, b) => a + b) : 0;

  getAverageOption = ratings => {
    let count = 0;
    Object.entries(ratings.totals).forEach(item => {
      count += (parseInt(item[0], 10) + 1) * item[1];
    });
    return Math.round(count / this.getTotalRatingsCount(ratings));
  };

  getCommonOption = ratings => {
    const rating = {
      value: '',
      count: 0,
    };
    Object.entries(ratings.totals).forEach(item => {
      if (item[1] >= rating.count) {
        rating.value = `${parseInt(item[0], 10) + 1}`;
        rating.count = item[1];
      }
    });
    return Number(rating.value);
  };

  getPercentage = (a, b) => `${Math.round((a / b) * 100)}%`;

  getTableData = (ratingResults, ratingComponents) => {
    const tableData = {};
    Object.entries(ratingResults).forEach(rating => {
      if (ratingComponents[rating[0]] && this.getTotalRatingsCount(rating[1])) {
        tableData[rating[0]] = {
          date: rating[1].date,
          title:
            ratingComponents[rating[0]].title ||
            ratingComponents[rating[0]].caption ||
            '',
          total: this.getTotalRatingsCount(rating[1]),
          average: this.generateStars(this.getAverageOption(rating[1])),
          common: this.generateStars(this.getCommonOption(rating[1])),
          selected: rating[1].selected,
        };
      }
    });
    return tableData;
  };

  getCSV = (data, ratings, selected) => {
    const wb = XLSX.utils.book_new();
    const { t } = this.props;
    const summaryData = [];
    const allRatingsData = [];
    selected.forEach(rating => {
      summaryData.push({
        [t('Date')]: formatDate(ratings[rating].date),
        [t('Name')]: ratings[rating].title || ratings[rating].caption,
        [t('Total Ratings')]: this.getTotalRatingsCount(data[rating]),
        [t('Average Ratings')]: this.getAverageOption(data[rating]),
        [t('Most Common Rating')]: this.getCommonOption(data[rating]),
      });

      const feedback = objectToArray(data[rating].feedback);
      const formattedFeeback = feedback.map(item => ({
        [t('Date')]: formatDate(item.value.date),
        [t('Email')]: item.value.email,
        [t('Name')]: item.value.name,
        [t('Comment')]: item.value.comment,
        [t('User Rating')]: item.value.userRating,
        [t('Rating Title')]: ratings[rating].title || ratings[rating].caption,
      }));

      allRatingsData.push({
        name: ratings[rating].title || ratings[rating].caption,
        data: formattedFeeback,
      });
    });

    const ws = XLSX.utils.json_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(wb, ws, t('Summary'));
    allRatingsData.map(item =>
      XLSX.utils.book_append_sheet(
        wb,
        XLSX.utils.json_to_sheet(item.data),
        item.name && item.name.length > 13
          ? `${item.name.substring(0, 10)}...`
          : item.name || '-',
      ),
    );
    XLSX.writeFile(wb, 'Ratings.xlsx');
  };

  getActionMenu = () => {
    const {
      selected,
      filteredChunks,
      ratings,
      onDeleteSelected,
      t,
    } = this.props;
    return (
      <ActionMenu
        selected={selected}
        onDownloadSelected={() => {
          downloadCSV(
            this.getCSV(filteredChunks, ratings, selected),
            t('ratings'),
          );
        }}
        onDeleteSelected={() => onDeleteSelected(selected)}
        text={t('rating results')}
      />
    );
  };

  generateStars = starRating => {
    const stars = [...Array(parseInt(starRating, 10))].map((_, i) => (
      <Star key={uuidv1()} />
    ));
    const emptyStars = [...Array(5 - parseInt(starRating, 10))].map((_, i) => (
      <StarBorder key={uuidv1()} />
    ));
    return [...stars, ...emptyStars];
  };

  subHeaderContent = () => (
    <span>
      <Trans>Total rating components</Trans>:{' '}
      <b>{Object.keys(this.props.filteredRatings).length}</b>
    </span>
  );

  generateTableColumns = () => {
    const { items, t } = this.props;
    return [
      {
        id: 'date',
        header: <Trans>Date Created</Trans>,
        classNames: 'col-xs-2 created-at fix-left',
        containerElement: ({ value }) => (
          <span>{value && formatDate(value)}</span>
        ),
      },
      {
        id: 'title',
        header: t('Name'),
        classNames: 'col-xs-2 fix-left',
      },
      {
        id: 'total',
        header: t('Total'),
        classNames: 'col-xs-2 total fix-left',
      },
      {
        id: 'average',
        header: t('Average Rating'),
        classNames: 'col-xs-2 average fix-left',
      },
      {
        id: 'common',
        header: t('Most Common'),
        classNames: 'col-xs-3 common fix-left',
      },
      {
        id: 'selected',
        header: this.getSelectAllCheckbox(),
        classNames: `col-xs-1 ${items.length < 4 ? 'fix-left' : 'selected'}`,
        containerElement: ({ value, rowId }) => (
          <>
            <TableCheckbox
              value={value}
              onSelect={() => this.props.onSelect(rowId)}
            />
            <ActionMenu
              selected={[rowId]}
              onDownloadSelected={() => {
                downloadCSV(
                  this.getCSV(this.props.filteredChunks, this.props.ratings, [
                    rowId,
                  ]),
                  t('ratings'),
                );
              }}
              onDeleteSelected={() => this.props.onDeleteSelected([rowId])}
              onEditSelected={() => this.props.onOpenRatingDetail(rowId)}
              text={t('rating result')}
            />
          </>
        ),
      },
    ];
  };

  hasFeedback = filteredChunks =>
    Object.values(filteredChunks).filter(chunk => chunk.feedback).length > 0
      ? null
      : 'no feedback';

  render() {
    const {
      items,
      filteredChunks,
      ratings,
      onOpenRatingDetail,
      onFilterRatingData,
      error,
      onSort,
      order,
      colSorted,
      filter,
      selectAll,
      onSelectAllResponses,
      onDeselectAllResponses,
      t,
    } = this.props;

    const ratingInstructions = (
      <p className="instructions-container">
        <p className="instructions-section-1">
          <span>
            <Trans>
              To use this feature add a Rating component to your app
            </Trans>
            .
          </span>
          <br />
          <span>
            <Trans>
              To add Rating component go to the Components menu and click on Add
              Rating component
            </Trans>
            .
          </span>
          <br />
        </p>
        <p className="instructions-section-2">
          <span>
            <Trans>
              When your app users submit a Rating, the Rate Data will be
              displayed here
            </Trans>
            . 
          </span>
        </p>
          
      </p>
    );

    return (
      <section className="rating-table-container">
        <Header
          title={this.subHeaderContent()}
          actionMenu={this.getActionMenu()}
          onChange={onFilterRatingData}
          filter={filter}
          selectAll={selectAll}
          onSelectAllResponses={onSelectAllResponses}
          onDeselectAllResponses={onDeselectAllResponses}
        />
        <ResultsTable
          {...this.props}
          error={error || this.hasFeedback(filteredChunks)}
          columns={this.generateTableColumns()}
          chunks={this.getTableData(filteredChunks, ratings)}
          emptyMessage={t('Looks like you have no rating responses yet')}
          optionalMessage={ratingInstructions}
          onRowTouchTap={onOpenRatingDetail}
          sortTable={cols => onSort(cols)}
          order={order}
          colSorted={colSorted}
          colsToSort={['date']}
        />
        {items && filteredChunks && <RatingDetails />}
      </section>
    );
  }
}

export default withTranslation()(Rating);
