/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { size } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Clear';
import Checkbox from '@material-ui/core/Checkbox';
import { Trans, useTranslation } from 'react-i18next';

import TryAgain from 'app/components/tryAgain';
import Header from 'app/components/header';
import ActionMenu from 'app/components/actionMenu';
import ResultsTable from 'app/components/resultsTable';
import { downloadCSV, formatDate } from 'utils';
import TableCheckbox from '../../components/tableCheckbox';
import { whitelabeledHost } from '../../../../../../utils/whitelabeledHost';

import './styles.css';
import themeStyles from '../../../../../../_export.scss';

const isWhiteLabelledHost = whitelabeledHost();

const isCheckboxDisabled = (error, pending) => error && !pending;

const Buyers = ({
  history,
  match,
  pending,
  items,
  chunks,
  filteredChunks,
  onFilterBuyerData,
  selected,
  onSelectAllBuyers,
  onSelect,
  onDeselectAllBuyers,
  error,
  selectAll,
  getBuyers,
  getGroups,
  stopGetBuyers,
  onDeleteSelected,
  onEmailSelected,
  isAppAdmin,
  onSort,
  colSorted,
  order,
  groups,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    getBuyers();
    getGroups();
    return stopGetBuyers;
  }, []);

  const getGroupChips = value =>
    value && Object.keys(value).length > 0 ? (
      <span>
        {Object.keys(value).map((group, index) => {
          if (groups[group] && groups[group].name) {
            return Object.keys(value)[index + 1]
              ? `${groups[group].name}, `
              : `${groups[group].name}`;
          }
        })}
      </span>
    ) : null;

  const getTitle = (selectedBuyers, buyers) => (
    <span>
      <Trans>Buyers</Trans>:{' '}
      {selectedBuyers.length
        ? `${selectedBuyers.length} / ${Object.keys(buyers).length}`
        : Object.keys(buyers).length}
    </span>
  );

  const getCsvData = (selectdBuyers, contacts) => {
    const csvColumns = [
      t('First name'),
      t('Last name'),
      t('Email'),
      t('Created'),
      t('Groups'),
      t('Phone'),
      t('Verified'),
      t('Organisation'),
      t('Role'),
      t('Allow chat'),
      t('Active'),
      t('Show email in search'),
      t('Show profile in search'),
      t('Social links'),
    ];
    let data = `${csvColumns.join(',')}\n`;

    selectdBuyers.forEach(key => {
      const groupkeys = Object.keys(contacts[key].groups);
      const socialLinksString =
        (contacts[key].socialLinks && contacts[key].socialLinks.join(', ')) ||
        '';

      data += `${contacts[key].firstName},${contacts[key].lastName},${
        contacts[key].email
      },${formatDate(contacts[key].created_at)},${groupkeys.join(' ')},${
        contacts[key].phoneNumber
      },${contacts[key].emailVerified ? t('yes') : t('no')},${
        contacts[key].organisation
      },${contacts[key].role},${contacts[key].allowChat ? t('yes') : t('no')},${
        contacts[key].isActive ? t('yes') : t('no')
      },${contacts[key].showEmailInSearch ? t('yes') : t('no')},${
        contacts[key].showProfileInSearch ? t('yes') : t('no')
      },${socialLinksString}\n`;
    });

    return data;
  };

  const getSelectAllCheckBox = () => (
    <div style={{ display: 'flex' }}>
      <Checkbox
        checked={selectAll}
        onChange={(e, isChecked) =>
          isChecked ? onSelectAllBuyers() : onDeselectAllBuyers()
        }
        disabled={isCheckboxDisabled(error, pending)}
        style={{ marginLeft: -2, color: themeStyles.primaryColor }}
        disableRipple
      />
      <ActionMenu
        selected={selected}
        onDeleteSelected={() => onDeleteSelected(selected, chunks)}
        onDownloadSelected={() => {
          downloadCSV(getCsvData(selected, chunks), 'buyers');
        }}
        onEmailSelected={emailFields =>
          onEmailSelected(selected, chunks, emailFields)
        }
        text={t('buyers')}
      />
    </div>
  );

  const generateTableColumns = () => {
    const suffixClass = items.length < 4 ? 'fix-left' : 'selected';
    const columns = [
      {
        ids: ['firstName', 'lastName'],
        header: t('Name'),
        classNames: 'col-xs-3',
        containerElement: ({ value }) => <div>{value}</div>,
      },
      {
        id: 'email',
        header: t('Email'),
        classNames: 'col-xs-4',
        containerElement: ({ value }) => <div>{value}</div>,
      },
      {
        id: 'emailVerified',
        header: t('Verified'),
        classNames: 'col-xs-1 email-verified',
        containerElement: ({ value }) => (
          <div>{value ? <Done /> : <Clear />}</div>
        ),
      },
      {
        id: 'created_at',
        header: <Trans>Date Created</Trans>,
        classNames: 'col-xs-3 created-at',
        containerElement: ({ value }) => (
          <span>{value && moment(value).format('DD/MM/YYYY')}</span>
        ),
      },
      // {
      //   id: 'groups',
      //   header: 'Groups',
      //   classNames: 'col-xs-2',
      //   containerElement: ({ value, rowId }) => getGroupChips(value, rowId),
      // },
      {
        id: 'selected',
        header: getSelectAllCheckBox(),
        classNames: `col-xs-1 ${suffixClass}`,
        containerElement: ({ value, rowId }) => (
          <>
            <TableCheckbox value={value} onSelect={() => onSelect(rowId)} />
            <ActionMenu
              selected={[rowId]}
              onDeleteSelected={() => onDeleteSelected([rowId], chunks)}
              onDownloadSelected={() => {
                downloadCSV(getCsvData([rowId], chunks), 'buyers');
              }}
              onEmailSelected={emailFields =>
                onEmailSelected([rowId], chunks, emailFields)
              }
              onEditSelected={() => {
                history.push(
                  `/${match.params.appname}/ecommerce/marketplace/buyers/${rowId}`,
                );
              }}
              text={t('buyer')}
            />
          </>
        ),
      },
    ];
    return columns;
  };

  const getPlaceholderText = () => (
    <>
      <p>
        <Trans>
          No buyers have registered to your app yet. To invite users to register
          as Buyers on your marketplace app, click on View App (top left
          corner), share your app URL with them and invite them to sign up as a
          buyer.
        </Trans>
      </p>
      <p>
        <Trans>
          In this section you will see the list of buyers who registered on your
          marketplace app. You can click on a buyer from the list and check
          their profile info, download their data, and delete their account. You
          can also send an email to all buyers in bulk.
        </Trans>
        <span>
          <Trans>
            To find out more about how your app users can sign up as buyers and
            make purchases from your app, click
          </Trans>{' '}
          <a
            href={
              !isAppAdmin && !isWhiteLabelledHost
                ? 'https://beezer.zendesk.com/hc/en-us/articles/6741896171025-Marketplace-How-to-make-purchases-from-marketplace-app'
                : 'https://kb-articles.s3.eu-west-2.amazonaws.com/Marketplace+-+How+to+make+purchases+in+the+marketplace+app.pdf'
            }
            rel="noopener noreferrer"
            style={{
              color: themeStyles.primaryColor,
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
            download
            target="_blank"
          >
            {} <Trans>here</Trans>
          </a>
          .
        </span>
      </p>
    </>
  );

  return (
    <>
      {error ? (
        <TryAgain
          message={t('Opps, something went wrong...')}
          onRetry={getBuyers}
        />
      ) : (
        <>
          <div style={{ height: 'calc(100% - 166px)' }} className="buyers-auth">
            <Header
              title={getTitle(selected, chunks)}
              onChange={size(chunks) > 0 ? onFilterBuyerData : null}
            />
            <ResultsTable
              items={items}
              chunks={filteredChunks}
              pending={pending}
              error={Object.keys(filteredChunks).length === 0}
              columns={generateTableColumns()}
              sortTable={cols => onSort(cols)}
              order={order}
              colSorted={colSorted}
              colsToSort={['created_at']}
              emptyMessage={getPlaceholderText()}
              onRowTouchTap={vendorID =>
                history.push(
                  `/${match.params.appname}/ecommerce/marketplace/buyers/${vendorID}`,
                )
              }
            />

            {/* <InfoDialog {...props} open={buyerDialogOpen} /> */}
          </div>
        </>
      )}
    </>
  );
};
Buyers.propTypes = {
  pending: PropTypes.bool.isRequired,
  filteredChunks: PropTypes.object,
  items: PropTypes.array.isRequired,
  error: PropTypes.string,
  chunks: PropTypes.object,
  getBuyers: PropTypes.func.isRequired,
  stopGetBuyers: PropTypes.func.isRequired,
  onFilterBuyerData: PropTypes.func.isRequired,
  selected: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onSelectAllBuyers: PropTypes.func.isRequired,
  onDeselectAllBuyers: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

Buyers.defaultProps = {
  error: null,
  filter: '',
  filteredChunks: {},
  chunks: {},
  selected: [],
};

export default Buyers;
