import React, { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { makeStyles } from '@material-ui/styles';
import {
  TableContainer,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Checkbox,
} from '@material-ui/core';
import Search from 'app/components/search';
import { downloadCSV, objectToArray } from 'utils';
import { isEmpty, size, get, set, reduce, keys, pickBy } from 'lodash';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import moment from 'moment';
import { Empty as EmptyIcon } from 'app/components/icons';
import { Link } from 'react-router-dom';
import ActionMenu from 'app/components/actionMenu';
import { Trans, useTranslation } from 'react-i18next';
import BookingDetails from '../bookingDetails/BookingDetails';
import { getAllBookings, cancelBooking } from '../../api';
import styles from './styles.module.scss';
import themeStyles from '../../../../../../_export.scss';

const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-head': {
      color: 'black',
      backgroundColor: '#EAEEFF',
      fontSize: 16,
      fontWeight: 600,
      fontFamily: 'Raleway, sans-serif',
      height: 73,
      textAlign: 'center',
    },
    '& .MuiTableCell-body': {
      color: 'black',
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Raleway, sans-serif',
      height: 73,
      textAlign: 'center',
      borderBottom: 'none',
    },
    '& .options-container': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .MuiCheckbox-root': {
      width: 24,
      height: 24,
    },
    '& .action-menu-container': {
      width: 24,
      height: 24,
    },
  },
});

const today = Date.now();

const BookingsTable = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const queryClient = useQueryClient();

  const [openDialog, setOpenDialog] = useState(false);
  const [bookingDetails, setBookingDetails] = useState({});
  const [bookingKey, setBookingKey] = useState('');
  const [timeFilter, setTimeFilter] = useState('all');
  const [bookingData, setBookingData] = useState();
  const [searchText, setSearchText] = useState('');
  const [dateOrderFilter, setDateOrderFilter] = useState('desc');
  const [bookingStatusFilter, setBookingStatusFilter] = useState('all');

  const { appname, staffData, metaData } = props;
  const { data, error, isLoading, isError } = useQuery(
    ['appointmentBookings', { appname }],
    getAllBookings,
  );
  const [chunks, setChunks] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (!isEmpty(data)) {
      const orderedBookingsData = orderBookingsByDate({
        order: dateOrderFilter,
        bookings: data,
      });
      setBookingData(orderedBookingsData);

      setBookingDetails(data[bookingKey]);
    }
  }, [data]);

  useEffect(() => {
    data && handleApplyFilter();
  }, [searchText, timeFilter, dateOrderFilter, bookingStatusFilter]);

  /** 
  ### Sort bookings by date order 
  * @param {{order:string, bookings:object}} 
  * @returns {object} sorted bookings object
  **/
  const orderBookingsByDate = ({ order, bookings }) =>
    Object.fromEntries(
      Object.entries(bookings).sort(([, bk1], [, bk2]) =>
        order === 'asc'
          ? bk1.appointmentDetail.date - bk2.appointmentDetail.date
          : bk2.appointmentDetail.date - bk1.appointmentDetail.date,
      ),
    );

  const handleApplyFilter = async () => {
    let timeValue = [0, 'days'];
    let pastDate = 0;

    if (timeFilter && timeFilter !== 'all') {
      timeValue = timeFilter.split(':');
      pastDate = moment()
        .subtract(timeValue[0], timeValue[1])
        .valueOf();
    }

    const bookings = Object.entries(data);
    const res = bookings.filter(booking => {
      const timeFilter = pastDate < booking[1].orderedAt;
      const searchFilter =
        (booking[1].bookingDetail.email &&
          booking[1].bookingDetail.email
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        (booking[1].bookingDetail.mobile &&
          booking[1].bookingDetail.mobile
            .toString()
            .includes(searchText.toLowerCase()));
      let statusFilter = true;

      if (bookingStatusFilter === 'active') {
        statusFilter =
          today < booking[1].appointmentDetail.date &&
          !booking[1].bookingDetail.cancelledByUser &&
          !booking[1].bookingDetail.cancelled;
      } else if (bookingStatusFilter === 'suspended') {
        statusFilter = booking[1].bookingDetail.cancelled;
      } else if (bookingStatusFilter === 'cancelled') {
        statusFilter = booking[1].bookingDetail.cancelledByUser;
      } else if (bookingStatusFilter === 'expired') {
        statusFilter =
          today > booking[1].appointmentDetail.date &&
          !booking[1].bookingDetail.cancelledByUser &&
          !booking[1].bookingDetail.cancelled;
      }
      return timeFilter && (searchFilter || !searchText) && statusFilter;
    });

    let filteredBookingData = {};
    let orderedBookingsData = {};
    if (!isEmpty(res)) {
      filteredBookingData = res.reduce(
        (object, [key, value]) => ({ ...object, [key]: value }),
        {},
      );
      orderedBookingsData = orderBookingsByDate({
        order: dateOrderFilter,
        bookings: filteredBookingData,
      });
    }

    setBookingData(orderedBookingsData);
  };

  const onCancelSelected = (selected, chunks) => {
    const staffDataArray = objectToArray(staffData);

    Promise.all(
      selected.map(key =>
        cancelBooking({
          appname,
          staffDetails: staffDataArray.find(
            staff => staff.name === chunks[key].bookingDetail.assignedTo,
          ),
          bookingSummary: chunks[key],
          metaData,
          source: 'platform',
        }),
      ),
    ).then(() => {
      queryClient.invalidateQueries('appointmentBookings');
    });
  };

  const getCsvData = (selected, chunks) => {
    const csvColumns = [
      t('Appointment'),
      t('Date/Time'),
      t('Customer Name'),
      t('Email'),
      t('Mobile'),
      t('Price'),
      t('Assignee'),
      t('Status'),
    ];
    let data = `${csvColumns.join(',')}\n`;
    selected.forEach(key => {
      data += `${chunks[key].appointmentDetail.name || '-'},${moment(
        chunks[key].appointmentDetail.date,
      ).format(`MM/DD/YY [- ${chunks[key].bookingDetail.time || ''}]`) ||
        '-'},${`${chunks[key].bookingDetail.firstName} ${chunks[key].bookingDetail.lastName}` ||
        '-'},${chunks[key].bookingDetail.email || '-'},${chunks[key]
        .bookingDetail.mobile || '-'},${chunks[key].finalBill || '0'},${chunks[
        key
      ].bookingDetail.assignedTo || '-'},${
        chunks[key].bookingDetail.cancelled ? t('Suspended') : ''
      }${chunks[key].bookingDetail.cancelledByUser ? t('Cancelled') : ''}${
        today < chunks[key].appointmentDetail.date &&
        !chunks[key].bookingDetail.cancelledByUser &&
        !chunks[key].bookingDetail.cancelled
          ? t('Active')
          : ''
      }${
        today > chunks[key].appointmentDetail.date &&
        !chunks[key].bookingDetail.cancelledByUser &&
        !chunks[key].bookingDetail.cancelled
          ? t('Expired')
          : ''
      }\n`;
    });
    return data;
  };

  useEffect(() => {
    setChunks(bookingData);
  }, [bookingData]);

  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  if (isError) {
    return (
      <span>
        <Trans>Error:</Trans> {error.message}
      </span>
    );
  }

  const onSelect = id => {
    const modifiedChunks = { ...chunks };
    const prevValue = get(modifiedChunks, `${id}.selected`, false);
    set(modifiedChunks, `${id}.selected`, !prevValue);
    setChunks(modifiedChunks);
    const selectedItems = keys(pickBy(modifiedChunks, chunk => chunk.selected));
    setSelected(selectedItems);
  };

  const selectAllAppointments = () => {
    const allIds = keys(chunks);
    const modifiedChunks = reduce(
      chunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: true,
        },
      }),
      {},
    );
    setChunks(modifiedChunks);
    setSelected(allIds);
    setSelectAll(true);
  };

  const deselectAllAppointments = () => {
    const modifiedChunks = reduce(
      chunks,
      (result, chunk, key) => ({
        ...result,
        [key]: {
          ...chunk,
          selected: false,
        },
      }),
      {},
    );
    setChunks(modifiedChunks);
    setSelected([]);
    setSelectAll(false);
  };
  return (
    <div className="scroll-container">
      <div className={`row middle-xs table-heading ${styles.scrollHeader}`}>
        <div className="col-xs-2">
          <span className={styles.headerLabel}>
            <Trans>Bookings</Trans>: {size(bookingData)}
          </span>
        </div>
        {!isEmpty(data) ? (
          <>
            <div className="col-xs-6">
              <Search
                name="search"
                placeholder={t('Search...')}
                value={searchText}
                onChange={val => setSearchText(val)}
                onClear={() => setSearchText('')}
              />
            </div>
            <div className="col-xs-2">
              <FormControl fullWidth>
                <InputLabel htmlFor="bookings-date" style={{ left: '-15px' }}>
                  <Trans>Bookings From</Trans>
                </InputLabel>
                <Select
                  variant="standard"
                  id="bookings-date"
                  value={timeFilter}
                  onChange={e => setTimeFilter(e.target.value)}
                >
                  <MenuItem value="all">
                    <Trans>All time</Trans>
                  </MenuItem>
                  <MenuItem value="30:days">
                    <Trans>Past 30 Days</Trans>
                  </MenuItem>
                  <MenuItem value="3:months">
                    <Trans>Past 3 Months</Trans>
                  </MenuItem>
                  <MenuItem value="6:months">
                    <Trans>Past 6 Months</Trans>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-xs-2">
              <FormControl fullWidth>
                <InputLabel htmlFor="bookings-date" style={{ left: '-15px' }}>
                  <Trans>Status</Trans>
                </InputLabel>
                <Select
                  variant="standard"
                  id="bookings-status"
                  value={bookingStatusFilter}
                  onChange={e => setBookingStatusFilter(e.target.value)}
                >
                  <MenuItem value="all">
                    <Trans>All</Trans>
                  </MenuItem>
                  <MenuItem value="active">
                    <Trans>Active</Trans>
                  </MenuItem>
                  <MenuItem value="cancelled">
                    <Trans>Cancelled</Trans>
                  </MenuItem>
                  <MenuItem value="suspended">
                    <Trans>Suspended</Trans>
                  </MenuItem>
                  <MenuItem value="expired">
                    <Trans>Expired</Trans>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {isEmpty(chunks) ? (
        <div className="empty-state">
          <EmptyIcon />
          <h5>
            <Trans>You don't have any bookings yet.</Trans>
          </h5>
          <p style={{ textAlign: 'center' }}>
            <Trans>
              Your app users will be able to make a booking by selecting one of
              your appointments within the app. Start by adding your first
              appointment
            </Trans>{' '}
            <Link to={`/${appname}/ecommerce/appointments`}>
              <Trans>here</Trans>
            </Link>
          </p>
        </div>
      ) : (
        <>
          <TableContainer
            component={Paper}
            style={{ maxHeight: 650, borderRadius: 15, boxShadow: 'none' }}
          >
            <Table
              stickyHeader
              aria-label="customized table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Trans>Appointment</Trans>
                  </TableCell>
                  <TableCell>
                    <div className={styles.dateHeader}>
                      <Trans>Date/Time</Trans>
                      {dateOrderFilter === 'desc' ? (
                        <IconButton
                          style={{ padding: '0px' }}
                          onClick={() => setDateOrderFilter('asc')}
                          color="primary"
                          title={t('Sort by oldest')}
                        >
                          <ArrowDropUpIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          style={{ padding: '0px' }}
                          onClick={() => setDateOrderFilter('desc')}
                          color="primary"
                          title={t('Sort by newest')}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Trans>Name</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Email</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Mobile</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Price</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Assignee</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Status</Trans>
                  </TableCell>
                  <TableCell>
                    <div className="options-container">
                      <Checkbox
                        disableRipple
                        checked={selectAll || false}
                        onChange={(e, isChecked) =>
                          isChecked
                            ? selectAllAppointments()
                            : deselectAllAppointments()
                        }
                        style={{ color: themeStyles.primaryColor }}
                      />
                      <ActionMenu
                        selected={selected}
                        onCancelSelected={() =>
                          onCancelSelected(selected, chunks)
                        }
                        onDownloadSelected={() => {
                          downloadCSV(
                            getCsvData(selected, chunks),
                            t('booking'),
                          );
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {!isEmpty(bookingData) > 0 &&
                  Object.keys(chunks).map(key => (
                    <TableRow
                      hover
                      key={key}
                      onClick={() => {
                        setOpenDialog(true);
                        setBookingDetails(chunks[key]);
                        setBookingKey(key);
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {chunks[key].appointmentDetail.name || '-'}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {moment(chunks[key].appointmentDetail.date).format(
                          `MM/DD/YY [${`${moment(
                            chunks[key].appointmentDetail.date,
                          ).format('HH:mm')} - ${moment(
                            chunks[key].appointmentDetail.date,
                          )
                            .add(chunks[key].bookingDetail.duration, 'minutes')
                            .format('HH:mm')}` || ''}]`,
                        ) || '-'}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {`${chunks[key].bookingDetail.firstName ||
                          '-'} ${chunks[key].bookingDetail.lastName || ''}`}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {chunks[key].bookingDetail.email || '-'}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {chunks[key].bookingDetail.mobile || '-'}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {chunks[key].finalBill || '0'}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {chunks[key].bookingDetail.assignedTo || '-'}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {chunks[key].bookingDetail.cancelled && (
                          <span className={styles.error}>
                            <Trans>Suspended</Trans>
                          </span>
                        )}
                        {chunks[key].bookingDetail.cancelledByUser && (
                          <span className={styles.error}>
                            <Trans>Cancelled</Trans>
                          </span>
                        )}
                        {today < chunks[key].appointmentDetail.date &&
                          !chunks[key].bookingDetail.cancelledByUser &&
                          !chunks[key].bookingDetail.cancelled && (
                            <span className={styles.success}>
                              <Trans>Active</Trans>
                            </span>
                          )}
                        {today > chunks[key].appointmentDetail.date &&
                          !chunks[key].bookingDetail.cancelledByUser &&
                          !chunks[key].bookingDetail.cancelled && (
                            <span className={styles.error}>
                              <Trans>Expired</Trans>
                            </span>
                          )}
                      </TableCell>
                      <TableCell>
                        <div className="options-container">
                          <Checkbox
                            disableRipple
                            checked={chunks[key].selected || false}
                            onClick={e => e.stopPropagation()}
                            onChange={e => {
                              e.stopPropagation();
                              onSelect(key);
                            }}
                            style={{ color: themeStyles.primaryColor }}
                          />
                          <ActionMenu
                            selected={[key]}
                            onCancelSelected={
                              !chunks[key].bookingDetail.cancelled
                                ? () => onCancelSelected([key], chunks)
                                : null
                            }
                            onEditSelected={() => {
                              setOpenDialog(true);
                              setBookingDetails(chunks[key]);
                              setBookingKey(key);
                            }}
                            onDownloadSelected={() =>
                              downloadCSV(
                                getCsvData([key], chunks),
                                t('booking'),
                              )
                            }
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {!isEmpty(bookingDetails) && (
              <BookingDetails
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                bookingDetails={bookingDetails}
                staffData={staffData}
                metaData={metaData}
                appname={appname}
              />
            )}
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default BookingsTable;
