import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import Header from './Header';
import { designChange, fieldChange, loadGoogleAnalytics } from './actions';

const mapStateToProps = (state, ownProps) => {
  const layout = state.editor.data.layoutData[ownProps.match.params.appname];

  const data = ownProps.data;
  const formattedData = {
    ...data,
    link: {
      ...data.link,
      linkType: {
        ...data.link.linkType,
        auth: data.link.linkType.auth,
      },
    },
  };
  return {
    ...ownProps,
    data: formattedData,
    googleAnalytics: state.app.googleAnalytics,
    pages: layout
      ? layout.childRoutes.map(key => state.editor.data.alwaysData[key])
      : [],
    design: ownProps.component.design,
    appname: ownProps.match.params.appname,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (field, value) =>
    dispatch(
      fieldChange(
        ownProps.match.params.appname,
        ownProps.component.lazy_data,
        field,
        value,
      ),
    ),
  onLoadGoogleAnalytics: () =>
    dispatch(loadGoogleAnalytics(ownProps.match.params.appname)),
  onDesignChange: (field, value) => {
    return dispatch(
      designChange(
        ownProps.match.params.appname,
        queryString.parse(ownProps.location.search).component,
        field,
        value,
      ),
    );
  },
  onButtonDesignChange: (type, value) => {
    return dispatch(
      designChange(
        ownProps.match.params.appname,
        queryString.parse(ownProps.location.search).component,
        `buttonDesign/${type}`,
        value,
      ),
    );
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
