import React from 'react';

const Blog = () => (
  <svg
    className="blogIcon"
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M710 4850 c-115 -16 -202 -62 -282 -148 -60 -65 -99 -139 -117 -220
-16 -75 -16 -3768 0 -3844 39 -187 205 -341 394 -368 95 -13 3615 -13 3710 0
189 27 353 180 394 368 16 75 16 3768 0 3844 -40 189 -208 343 -400 368 -88
11 -3617 11 -3699 0z m3743 -131 c105 -35 188 -115 228 -219 16 -42 19 -77 19
-220 l0 -170 -2145 0 -2145 0 0 133 c0 155 12 232 47 300 44 87 142 165 237
188 17 4 856 7 1864 8 l1833 1 62 -21z m257 -2347 c0 -1133 -3 -1646 -11
-1687 -19 -104 -69 -182 -152 -238 -106 -72 40 -67 -1988 -67 -1801 0 -1828 0
-1890 20 -107 34 -187 110 -231 218 l-23 57 -3 1663 -2 1662 2150 0 2150 0 0
-1628z"
      />
      <path
        d="M909 4479 c-29 -29 -23 -85 12 -112 74 -54 153 46 91 116 -21 24 -77
22 -103 -4z"
      />
      <path
        d="M1271 4474 c-12 -15 -21 -38 -21 -51 0 -32 42 -73 75 -73 36 0 75 42
75 80 0 67 -88 97 -129 44z"
      />
      <path
        d="M1642 4483 c-39 -34 -30 -103 17 -123 55 -22 101 9 101 70 0 29 -6
43 -22 54 -30 21 -71 20 -96 -1z"
      />
      <path
        d="M2732 4468 c-17 -17 -15 -64 4 -82 14 -14 93 -16 762 -14 l746 3 15
26 c13 24 13 29 -2 53 l-17 26 -748 0 c-568 0 -751 -3 -760 -12z"
      />
      <path
        d="M1007 3576 c-109 -30 -214 -140 -237 -249 -7 -37 -10 -260 -8 -713
l3 -659 29 -62 c31 -69 88 -128 158 -165 l43 -23 975 0 975 0 50 27 c62 32
129 103 158 166 22 47 22 51 22 747 l0 700 -34 63 c-40 74 -84 115 -161 151
l-55 26 -935 2 c-738 1 -945 -1 -983 -11z m1921 -122 c47 -19 88 -58 115 -109
22 -40 22 -43 22 -690 0 -357 -4 -663 -8 -679 -8 -29 -10 -27 -255 217 -161
160 -260 251 -284 261 -48 20 -118 20 -165 1 -22 -9 -94 -72 -173 -150 l-135
-134 -170 232 c-94 127 -183 247 -199 265 -41 46 -82 65 -146 66 -104 0 -109
-4 -391 -389 l-254 -347 -3 644 c-2 414 1 655 7 678 14 49 63 104 113 129 43
21 46 21 966 21 812 0 927 -2 960 -16z m-1361 -847 c11 -7 150 -187 307 -402
l286 -390 -537 -3 c-567 -3 -594 -1 -648 42 l-29 23 25 34 c165 234 505 687
522 696 12 7 29 12 37 12 8 0 25 -5 37 -12z m1158 -507 l249 -249 -46 -21
c-44 -19 -63 -20 -334 -18 l-288 3 -94 129 -94 128 133 135 c74 74 143 139
154 143 11 5 31 7 45 4 16 -3 120 -100 275 -254z"
      />
      <path
        d="M2177 3286 c-191 -53 -249 -297 -105 -437 133 -129 370 -66 422 112
20 65 20 92 1 155 -38 130 -183 208 -318 170z m141 -126 c116 -71 85 -232 -51
-264 -40 -10 -112 25 -137 66 -27 45 -27 117 2 153 50 64 125 82 186 45z"
      />
      <path
        d="M3455 3286 c-11 -8 -18 -26 -18 -46 0 -62 -14 -60 543 -60 557 0 550
-1 550 60 0 61 7 60 -550 60 -411 0 -510 -3 -525 -14z"
      />
      <path
        d="M3460 2802 c-28 -22 -30 -70 -4 -89 14 -10 120 -13 529 -13 483 0
513 1 528 18 24 26 21 68 -5 86 -20 14 -86 16 -524 16 -469 0 -503 -1 -524
-18z"
      />
      <path
        d="M3460 2322 c-28 -22 -30 -70 -4 -89 14 -10 120 -13 529 -13 483 0
513 1 528 18 24 26 21 68 -5 86 -20 14 -86 16 -524 16 -469 0 -503 -1 -524
-18z"
      />
      <path
        d="M3460 1842 c-28 -22 -30 -70 -4 -89 14 -10 120 -13 529 -13 491 0
513 1 529 19 25 27 17 72 -14 88 -19 10 -143 13 -521 13 -465 0 -498 -1 -519
-18z"
      />
      <path
        d="M692 1358 c-20 -20 -15 -76 7 -88 27 -14 3695 -14 3722 0 22 12 27
68 7 88 -17 17 -3719 17 -3736 0z"
      />
      <path
        d="M692 878 c-20 -20 -15 -76 8 -88 12 -7 132 -9 347 -8 l328 3 13 28
c12 24 11 31 -2 53 l-16 24 -333 0 c-246 0 -336 -3 -345 -12z"
      />
      <path
        d="M1592 878 c-16 -16 -15 -73 2 -87 9 -8 149 -10 502 -9 l488 3 15 26
c13 24 13 29 -2 53 l-17 26 -488 0 c-366 0 -491 -3 -500 -12z"
      />
      <path
        d="M2913 864 c-15 -24 -15 -29 -2 -53 l15 -26 737 -3 c498 -1 744 1 758
8 22 12 27 68 7 88 -9 9 -190 12 -755 12 l-743 0 -17 -26z"
      />
    </g>
  </svg>
);

export default Blog;
