import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { TableContainer, Checkbox, CircularProgress } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import ActionMenu from 'app/components/actionMenu';
import Search from 'app/components/search';
import { Empty as EmptyIcon } from 'app/components/icons';
import { downloadCSV, formatDate } from 'utils';

import styles from './styles.module.scss';
import ZoomDisabledWrapper from './components/ZoomDisabledWrapper';
import themeStyles from '../../../../_export.scss';

const useStyles = makeStyles(() => ({
  table: {
    '& .MuiTableCell-head': {
      color: 'black',
      backgroundColor: '#EAEEFF',
      fontSize: 16,
      fontWeight: 'bold',
      fontFamily: 'Raleway, sans-serif!important',
      textAlign: 'center',
      borderBottom: 'none',
    },
    '& .MuiTableCell-body': {
      color: 'black',
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'Raleway, sans-serif',
      textAlign: 'center',
      borderBottom: '1px solid #d3d3d3',
    },
    '& td > .options-container': {
      '& span': {
        color: `${themeStyles.primaryColor}!important`,
      },
      '& svg': {
        fill: `${themeStyles.primaryColor}!important`,
      },
    },
  },
  tableLeftCorner: { borderTopLeftRadius: 10 },
  tableRightCorner: {
    borderTopRightRadius: 10,
    '& span': {
      color: `${themeStyles.primaryColor}!important`,
    },
    '& svg': {
      fill: `${themeStyles.primaryColor}!important`,
    },
  },
}));

const Zoom = ({
  chunks: users,
  filteredChunks: filteredUsers,
  getUsers,
  getGroups,
  history,
  pending,
  filter,
  resetUsersData,
  selectAll,
  selected,
  onSelectAllUsers,
  onDeselectAllUsers,
  groups,
  onSelect,
  onFilterUserData,
  onUpdateAssistantChecked,
  onUpdateChecked,
  zoomComponentEnabled,
  onAddZoomComponent,
  onDeleteSelected,
}) => {
  const { t } = useTranslation();

  const [totalUsers, setTotalUsers] = useState(0);
  const [zoomHostExists, setZoomHostExists] = useState(false);

  const classes = useStyles();

  const getCsvData = (selectedUserKeys, users) => {
    const csvColumns = [
      t('First name'),
      t('Last name'),
      t('Email'),
      t('Date created'),
      t('Groups'),
      t('Phone'),
      t('Verified'),
      t('Host access'),
      t('Assistant access'),
    ];
    let data = `${csvColumns.join(',')}\n`;

    selectedUserKeys.forEach(userKey => {
      const groupNames = [];
      const groupKeys = Object.keys(
        users[userKey].groups ? users[userKey].groups : {},
      );
      groupKeys.forEach(groupKey => {
        if (groups[groupKey]) groupNames.push(groups[groupKey].name);
      });

      data += `${users[userKey].firstName},${users[userKey].lastName},${
        users[userKey].email
      },${formatDate(users[userKey].created_at)},"${groupNames.join(
        ',',
      )}",${users[userKey].phoneNumber || '-'},${
        users[userKey].emailVerified ? t('yes') : t('no')
      },${users[userKey].isZoomHost ? t('yes') : t('no')},${
        users[userKey].isZoomAssistant ? t('yes') : t('no')
      }\n`;
    });

    return data;
  };

  const handleHostCheck = (isZoomHost, user) => {
    if (zoomHostExists && !isZoomHost) {
      alert(t('Can only have one zoom host!'));
      return;
    }
    setZoomHostExists(false);

    confirmAlert({
      title: `${t('Confirm to')} ${isZoomHost ? t('remove') : t('grant')} ${t(
        'host access.',
      )}`,
      message: `${
        isZoomHost
          ? `${t('Are you sure you want to remove')} ${
              filteredUsers[user].email
            } ${t('from having host access?')}`
          : `${t('Are you sure you want to grant')} ${
              filteredUsers[user].email
            } ${t(
              'host access? Please make sure this is the right email to prevent misuse.',
            )}`
      }`,
      buttons: [
        {
          label: t('Yes'),
          onClick: () => onUpdateChecked(isZoomHost, user),
        },
        {
          label: t('No'),
          onClick: () => {},
        },
      ],
    });
  };

  const handleAssistantCheck = (isZoomAssistant, user) => {
    if (filteredUsers[user].isZoomHost) {
      alert(t('Cannot make a host an assistant!'));
      return;
    }
    confirmAlert({
      title: `${t('Confirm to')} ${
        isZoomAssistant ? t('remove') : t('grant')
      } ${t('assistant access.')}`,
      message: `${
        isZoomAssistant
          ? `${t('Are you sure you want to remove')} ${
              filteredUsers[user].email
            } ${t('from having assistant access?')}`
          : `${t('Are you sure you want to grant')} ${
              filteredUsers[user].email
            } ${t(
              'assistant access? Please make sure this is the right email to prevent misuse.',
            )}`
      }`,
      buttons: [
        {
          label: t('Yes'),
          onClick: () => onUpdateAssistantChecked(isZoomAssistant, user),
        },
        {
          label: t('No'),
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    resetUsersData();
    getUsers();
    getGroups();
    return () => {
      resetUsersData();
    };
  }, []);

  useEffect(() => {
    const size = Object.keys(filteredUsers).length;
    setTotalUsers(size);
  }, [filteredUsers]);

  const content = (
    <>
      <div className={styles.header}>
        <span className={styles.header_text}>
          <Trans>Total Users</Trans>: <b>{totalUsers}</b>
        </span>
        <div className={styles.header_search}>
          <Search
            name="search"
            placeholder={t('Search...')}
            value={filter}
            onChange={val => onFilterUserData(val)}
            onClear={() => onFilterUserData('')}
          />
        </div>
      </div>

      {pending && <CircularProgress />}
      {totalUsers === 0 && !pending ? (
        <div className="empty-state">
          <EmptyIcon />
          <h5>
            <Trans>You don't have any users.</Trans>
          </h5>
        </div>
      ) : (
        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
          <Table
            aria-label={t('customized table')}
            className={classes.table}
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell width="18%" className={classes.tableLeftCorner}>
                  <Trans>User Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Email</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Host Access</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Assistant Access</Trans>
                </TableCell>
                <TableCell className={classes.tableRightCorner}>
                  <div className="options-container">
                    <Checkbox
                      disableRipple
                      style={{ color: themeStyles.primaryColor }}
                      checked={!!selectAll}
                      onChange={(e, isChecked) =>
                        isChecked ? onSelectAllUsers() : onDeselectAllUsers()
                      }
                    />
                    <ActionMenu
                      selected={selected}
                      onDeleteSelected={() => onDeleteSelected(selected, users)}
                      onDownloadSelected={() => {
                        downloadCSV(getCsvData(selected, users), 'zoom-users');
                      }}
                      text={t('users')}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers &&
                Object.keys(filteredUsers).map(key => {
                  if (!zoomHostExists && filteredUsers[key].isZoomHost) {
                    setZoomHostExists(true);
                  }
                  return (
                    <TableRow key={filteredUsers[key].email}>
                      <TableCell component="th" scope="row">
                        {`${filteredUsers[key].firstName} ${filteredUsers[key].lastName}` ||
                          '-'}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {filteredUsers[key].email || '-'}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          style={{ color: themeStyles.primaryColor }}
                          checked={!!filteredUsers[key].isZoomHost}
                          onClick={() =>
                            handleHostCheck(
                              !!filteredUsers[key].isZoomHost,
                              key,
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          style={{
                            color: themeStyles.primaryColor,
                            padding: 0,
                          }}
                          checked={!!filteredUsers[key].isZoomAssistant}
                          onClick={() =>
                            handleAssistantCheck(
                              !!filteredUsers[key].isZoomAssistant,
                              key,
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <div className="options-container">
                          <Checkbox
                            checked={!!filteredUsers[key].selected}
                            onChange={() => onSelect(key)}
                            style={{ color: themeStyles.primaryColor }}
                            disableRipple
                          />
                          <ActionMenu
                            selected={[key]}
                            onDeleteSelected={() =>
                              onDeleteSelected([key], users)
                            }
                            onDownloadSelected={() =>
                              downloadCSV(getCsvData([key], users), 'users')
                            }
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );

  return !zoomComponentEnabled ? (
    <ZoomDisabledWrapper
      onAddZoomComponent={onAddZoomComponent}
      history={history}
    >
      {content}
    </ZoomDisabledWrapper>
  ) : (
    content
  );
};

export default Zoom;
