import i18n from 'i18next';
import firebase from '../../../../../../firebase';

import {
  openDialog,
  dialogActionComplete,
  reportDialogError,
} from '../../../../../../app/modules/dialog/actions';

/**********************************
 * REMOVING COMPONENTS/PAGES/TABS *
 **********************************/

export const getRemoveDialogText = (type, query, alwaysData) => {
  let text;

  switch (type) {
    case 'COMPONENT':
      text = `${alwaysData[query.component].type} ${i18n.t('component from')}
      ${
        query.tab
          ? alwaysData[query.page].tabs[query.tab].title
          : alwaysData[query.page].title
      }`;
      break;

    case 'TAB':
      text = `${alwaysData[query.page].tabs[query.tab].title} ${i18n.t(
        'tab from',
      )} ${alwaysData[query.page].title}`;
      break;

    case 'PAGE':
      text = `${alwaysData[query.page].title} ${i18n.t('page')}`;
      break;

    case 'FIELD':
      text = `this field`;
      break;

    case 'ITEM':
      text = i18n.t('this item');
      break;

    default:
      throw new Error(i18n.t('Type not recogized'));
  }

  return `${i18n.t('Are you sure you want to delete')} ${text}? ${i18n.t(
    "This won't take effect until you publish your changes.",
  )}`;
};

export const removePage = (appname, { page }) => async dispatch => {
  try {
    let pagesToRemove = [page];
    const layoutDataRef = firebase
      .database()
      .ref(`apps/${appname}/draft/layout_data`);

    const alwaysDataRef = firebase
      .database()
      .ref(`apps/${appname}/draft/always_data`);
    dispatch({ type: 'REMOVE_PAGE' });

    const layoutBeforeDelete = await layoutDataRef
      .child('childRoutes')
      .once('value')
      .then(snapshot => snapshot.val());
    //get nested pages for current page to be deleted
    const nestedPagesRef = await alwaysDataRef
      .orderByChild('parentPageKey')
      .equalTo(page)
      .once('value');
    const nestedPages = nestedPagesRef.val() || {};
    pagesToRemove = [...pagesToRemove, ...Object.keys(nestedPages)];
    await layoutDataRef.child('childRoutes').transaction(childRoutes => {
      let modifiedChildRoutes = childRoutes.filter(
        pageKey => !pagesToRemove.includes(pageKey),
      );
      return modifiedChildRoutes;
    });

    // check if we're deleting a homepage and set up a new one if so
    if (layoutBeforeDelete.indexOf(page) === 0) {
      await firebase
        .database()
        .ref(`apps/${appname}/draft/always_data/${layoutBeforeDelete[1]}`)
        .child('isHomepage')
        .set(true);
    }
  } catch (error) {
    dispatch({ type: 'ERROR_REMOVING_A_PAGE', error, page });
    console.error(error);
  }
};

export const removeTab = (appname, { page, tab }) => dispatch => {
  const pageRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${page}`);
  const index = tab;

  dispatch({ type: 'REMOVE_TAB' });

  return pageRef.child('tabs').transaction(tabs => {
    if (!tabs[index]) return tabs;

    return [...tabs.slice(0, index), ...tabs.slice(index + 1)];
  });
};

/**
 * Removes a component from the app's draft state
 * Removes all always and lazy data
 * @param {string} appname - the app to remove the compponent from
 * @param {string} componentId - the id of the component to remove
 * @param {string} parentId - the id of the component's parent to also remove
 */
export const removeComponent = (appname, query, history) => dispatch => {
  const { component } = query;
  firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${query.page}/unique`)
    .transaction(() => {
      return false;
    });
  const socialMediaRef = firebase
    .database()
    .ref(`social_media/instagramFeed/${appname}`);
  const alwaysDataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data`);
  // const lazyDataRef = firebase
  //   .database()
  //   .ref(`apps/${appname}/draft/lazy_data`);
  const componentRef = alwaysDataRef.child(`${component}`);
  const componentsArrayRef = query.tab
    ? alwaysDataRef.child(`${query.page}/tabs/${query.tab}/components`)
    : alwaysDataRef.child(`${query.page}/components`);

  dispatch({ type: 'REMOVE_COMPONENT' });

  return componentsArrayRef
    .transaction(value => {
      const index = value.indexOf(component);

      return [...value.slice(0, index), ...value.slice(index + 1)];
    })
    .then(() => componentRef.once('value'))
    .then(snapshot => {
      const lazyKey = snapshot.child('lazy_data').val();
      if (socialMediaRef) {
        socialMediaRef.on('value', snapshot => {
          if (snapshot.hasChild(lazyKey)) socialMediaRef.child(lazyKey).set({});
        });
      }
      // Uncommenting this will turn on proper deletion,
      // however there seem to be some other small issues to fix first
      // if (snapshot.exists() && snapshot.child('lazy_data').exists()) {
      //   const lazyKey = snapshot.child('lazy_data').val();
      //   lazyDataRef.child(`${lazyKey}`).remove();
      // }
      //
      // componentRef.remove();

      if (history) {
        if (query && query.page && query.tab) {
          history.push(
            `list?page=${query.page}&tab=${query.tab}${
              query.parentPage ? `&parentPage=${query.parentPage}` : ``
            }`,
          );
        } else if (query && query.page) {
          history.push(
            `list?page=${query.page}${
              query.parentPage ? `&parentPage=${query.parentPage}` : ``
            }`,
          );
        }
      }
    });
};

/**
 * Standard remove func
 */
export const removeItem = (
  appname,
  dataId,
  item,
  itemRef,
  history,
  query,
) => dispatch => {
  const dataRef = firebase.database().ref(itemRef);

  return dataRef
    .child('order')
    .transaction(values => {
      if (values === null) return null;

      const index = values.indexOf(item);

      if (index === -1) return values;

      return [...values.slice(0, index), ...values.slice(index + 1)];
    })
    .then(() => dataRef.child(`chunks/${item}`).remove())
    .then(() =>
      dispatch({
        type: 'REMOVE_ITEM',
        appname,
        dataId,
        item,
      }),
    )
    .then(() => {
      if (history) {
        if (query && query.page && query.tab && query.item && query.component) {
          history.push(
            `edit?page=${query.page}&tab=${query.tab}&component=${
              query.component
            }&item=${query.item}${
              query.parentPage ? `&parentPage=${query.parentPage}` : ``
            }`,
          );
        } else if (query && query.page && query.item && query.component) {
          history.push(
            `edit?page=${query.page}&component=${query.component}&item=${
              query.item
            }${query.parentPage ? `&parentPage=${query.parentPage}` : ``}`,
          );
        } else if (query && query.page) {
          history.push(
            `list?page=${query.page}${
              query.parentPage ? `&parentPage=${query.parentPage}` : ``
            }`,
          );
        }
      }
    });
};

export const removeEventListFilter = itemRef => dispatch => {
  dispatch({
    type: 'REMOVE_ITEM',
    itemRef,
  });
  return firebase
    .database()
    .ref(itemRef)
    .remove();
};

export const delegateRemoveItem = (appname, query, history) => (
  dispatch,
  getState,
) => {
  const { component, item } = query;
  // get component type
  const { lazy_data, type } = getState().editor.data.alwaysData[component];
  const itemRef = `apps/${appname}/draft/lazy_data/${lazy_data}`;

  switch (type) {
    case 'Schedule': {
      const { itinerary } = query;
      return dispatch(
        removeItem(
          appname,
          lazy_data,
          item,
          `${itemRef}/itinerary/${itinerary}/items`,
          history,
          query,
        ),
      );
    }
    case 'Roster': {
      return dispatch(
        removeItem(
          appname,
          lazy_data,
          item,
          `${itemRef}/items`,
          history,
          query,
        ),
      );
    }
    case 'Slider': {
      return dispatch(
        removeItem(
          appname,
          lazy_data,
          item,
          `${itemRef}/slides`,
          history,
          query,
        ),
      );
    }
    case 'Vote': {
      return dispatch(
        removeItem(
          appname,
          lazy_data,
          item,
          `${itemRef}/options`,
          history,
          query,
        ),
      );
    }
    case 'EventList': {
      return dispatch(removeEventListFilter(`${itemRef}/filters/${item}`));
    }
    case 'GoogleMaps': {
      return dispatch(
        removeItem(
          appname,
          lazy_data,
          item,
          `${itemRef}/markers`,
          history,
          query,
        ),
      );
    }
    case 'Form': {
      return dispatch(
        removeItem(
          appname,
          lazy_data,
          item,
          `${itemRef}/fields`,
          history,
          query,
        ),
      );
    }
    default:
      return dispatch(
        removeItem(
          appname,
          lazy_data,
          item,
          `${itemRef}/items`,
          history,
          query,
        ),
      );
  }
};

export const delegateRemoval = (type, appname, query, history) => dispatch => {
  switch (type) {
    case 'PAGE':
      return dispatch(removePage(appname, query));

    case 'TAB':
      return dispatch(removeTab(appname, query));

    case 'COMPONENT':
      return dispatch(removeComponent(appname, query, history));

    case 'ITEM':
      return dispatch(delegateRemoveItem(appname, query, history));

    default: {
      throw new Error(i18n.t('delegateRemoval: type not set'));
    }
  }
};

export const launchRemoveDialog = (appname, query, type, history) => (
  dispatch,
  getState,
) => {
  const { alwaysData } = getState().editor.data;

  const text = getRemoveDialogText(type, query, alwaysData);

  const options = {
    buttonLabel: i18n.t('Delete'),
    title: `${i18n.t('Delete')} ${type[0]}${type.substr(1).toLowerCase()}`,
    text,
  };

  return dispatch(openDialog(options))
    .then(val => {
      if (val.action === 'submit') {
        dispatch(delegateRemoval(type, appname, query, history));
      }
    })
    .then(() => dispatch(dialogActionComplete()))
    .catch(err => dispatch(reportDialogError(err)));
};
