/* eslint-disable max-len */
import i18n from 'i18next';

const seedComponent = (type, account, palette, tab) => {
  const enabled = false;
  const layout = '';
  const buttonDesign = {
    enabled: true,
    border: {
      width: 1,
      style: 'solid',
      color: palette.alternateTextColor,
    },
    backgroundColor: palette.primary1Color,
    color: palette.alternateTextColor,
    borderRadius: 32,
  };

  switch (type) {
    case 'Text':
      return {
        content: {
          content: `<p>${i18n.t(
            'Click on the editor pane on the left to start typing the text you want to',
          )} <strong>${i18n.t('display')}</strong>!  ${i18n.t(
            'and ensure you click on',
          )} <strong>"${i18n.t('Save')}"</strong> ${i18n.t('once done!')}</p>\n
          <p>${i18n.t(
            'Use the settings above to format your text as you want it to',
          )} <ins>${i18n.t('appear')}</ins>.</p>`,
        },
        layout: 'Standard',
        design: {},
      };

    case 'Roster':
      return {
        content: {
          items: {
            chunks: {
              '-KeL52ile-3EmpQ9NB8w': {
                avatar:
                  'https://beezer-img-storage.s3.amazonaws.com/UploadImageForUser.png',
                subtitle: i18n.t('Subtitle'),
                text: i18n.t('Description'),
                title: i18n.t('Roster Item 1'),
                linkedin: 'https://www.linkedin.com',
                twitter: 'https://www.twitter.com',
              },
            },
            order: ['-KeL52ile-3EmpQ9NB8w'],
          },
        },
        layout: 'Standard',
        design: {
          enabled,
          titleColor: palette.alternateTextColor,
          subTitleColor: palette.alternateTextColor,
          descriptionColor: palette.alternateTextColor,
        },
      };

    case 'Slider':
      return {
        content: {
          slides: {
            chunks: {
              '-KeL52ile-3EmpQ9NB8w': {
                image:
                  'https://beezer-img-storage.s3.amazonaws.com/images/43641dcb-b45f-47a2-b90a-cfef831b4d48.png',
                subtitle: i18n.t('Subtitle'),
                title: i18n.t('Title'),
                link: {
                  enabled: true,
                  button: {
                    enabled: true,
                    label: i18n.t('Button'),
                  },
                  linkType: {
                    page: '/',
                    selectedOption: 'page',
                    web: 'https://www.example.com',
                    phone: '+1-555-555-5555',
                    email: 'support@example.com',
                    auth: 'sign-up',
                  },
                },
              },
              '-KeL52ile-3EmpQ9NB8y': {
                image:
                  'https://beezer-img-storage.s3.amazonaws.com/images/e2512463-6ae3-4c98-8537-227a2d79730f.png',
                subtitle: i18n.t('Subtitle'),
                title: i18n.t('Title'),
                link: {
                  enabled: true,
                  button: {
                    enabled: true,
                    label: i18n.t('Button'),
                  },
                  linkType: {
                    page: '/',
                    selectedOption: 'page',
                    web: 'https://www.example.com',
                    phone: '+1-555-555-5555',
                    email: 'support@example.com',
                    auth: 'sign-up',
                  },
                },
              },
            },
            order: ['-KeL52ile-3EmpQ9NB8w', '-KeL52ile-3EmpQ9NB8y'],
          },
        },
        layout: 'Vertical',
        design: {
          enabled,
          buttonDesign,
          backgroundColor: palette.secondary1Color,
          gradientOverlay: 20,
          textColor: palette.alternateTextColor,
          gradientColor: {
            r: 0,
            g: 0,
            b: 0,
          },
          positionX: 50,
          positionY: 50,
        },
      };

    case 'Schedule':
      return {
        content: {
          categories: {
            '-KyuNiFvY2TdsYSEb1Tz': {
              color: palette.primary1Color,
              label: i18n.t('Category 1'),
            },
            '-KyuNj5GRLmo5R75lbE8': {
              color: palette.secondary1Color,
              label: i18n.t('Category 2'),
            },
          },
          itinerary: {
            '-KyuNn-7wagfausuSnEO': {
              items: {
                chunks: {
                  '-KyuNnJar198MOSfEFxm': {
                    category: '-KyuNiFvY2TdsYSEb1Tz',
                    subtitle: i18n.t('Subtitle'),
                    title: i18n.t('First Item'),
                    dialog: {
                      enabled: true,
                      avatar: 'http://via.placeholder.com/200x200',
                      name: i18n.t('Name'),
                      position: i18n.t('Position'),
                      description: i18n.t('Description'),
                    },
                  },
                  '-KyuNnJar198MOSfEFxP': {
                    category: '-KyuNiFvY2TdsYSEb1Tz',
                    subtitle: i18n.t('Subtitle'),
                    title: i18n.t('Second Item'),
                    dialog: {
                      enabled: true,
                      avatar: 'http://via.placeholder.com/200x200',
                      name: i18n.t('Name'),
                      position: i18n.t('Position'),
                      description: i18n.t('Description'),
                    },
                  },
                  '-KyuNnJar198MOSfEFxl': {
                    category: '-KyuNiFvY2TdsYSEb1Tz',
                    subtitle: i18n.t('Subtitle'),
                    title: i18n.t('Third Item'),
                    dialog: {
                      enabled: true,
                      avatar: 'http://via.placeholder.com/200x200',
                      name: i18n.t('Name'),
                      position: i18n.t('Position'),
                      description: i18n.t('Description'),
                    },
                  },
                },
                order: [
                  '-KyuNnJar198MOSfEFxm',
                  '-KyuNnJar198MOSfEFxP',
                  '-KyuNnJar198MOSfEFxl',
                ],
              },
              timestamp: `${new Date().toISOString()}`,
            },
            '-L4fZS0HqjZyLiqVKES4': {
              items: {
                chunks: {
                  '-KyuNnJar198MOSfEFxm': {
                    category: '-KyuNj5GRLmo5R75lbE8',
                    subtitle: i18n.t('Subtitle'),
                    title: i18n.t('First Item'),
                    dialog: {
                      enabled: true,
                      avatar: 'http://via.placeholder.com/200x200',
                      name: i18n.t('Name'),
                      position: i18n.t('Position'),
                      description: i18n.t('Description'),
                    },
                  },
                  '-KyuNnJar198MOSfEFxP': {
                    category: '-KyuNj5GRLmo5R75lbE8',
                    subtitle: i18n.t('Subtitle'),
                    title: i18n.t('Second Item'),
                    dialog: {
                      enabled: true,
                      avatar: 'http://via.placeholder.com/200x200',
                      name: i18n.t('Name'),
                      position: i18n.t('Position'),
                      description: i18n.t('Description'),
                    },
                  },
                },
                order: ['-KyuNnJar198MOSfEFxm', '-KyuNnJar198MOSfEFxP'],
              },
              timestamp: `${new Date().toISOString()}`,
            },
          },
        },
        layout: 'Ice',
        design: {
          enabled,
        },
      };

    case 'Form':
      return {
        content: {
          title: '',
          displayTitle: false,
          caption: '',
          buttonText: i18n.t('Submit'),
          fields: {
            chunks: {
              '-Kyn21hnw-1EklN8LA0z': {
                label: i18n.t('Name'),
                type: 'text',
                required: true,
              },
              '-Kzn77wre-9lmOl8YQ8y': {
                label: i18n.t('Email'),
                type: 'email',
                required: true,
              },
              '-Kzn77wre-9lmOl8YQ8b': {
                label: i18n.t('Message'),
                type: 'text',
                required: false,
              },
            },
            order: [
              '-Kyn21hnw-1EklN8LA0z',
              '-Kzn77wre-9lmOl8YQ8y',
              '-Kzn77wre-9lmOl8YQ8b',
            ],
          },
          sendTo: account,
        },
        layout: 'Material',
        design: {
          enabled,
          buttonDesign,
          textColor: palette.textColor,
          focusColor: palette.accent1Color,
        },
      };

    case 'Twitter':
      return {
        content: {
          twitterFeed: false,
          feedUser: '',
          feedAlignment: 'left',
          twitterFollow: false,
          followUser: '',
          followAlignment: 'left',
          twitterHashtag: false,
          hashtag: '',
          hashtagAlignment: 'left',
          twitterMention: false,
          mention: '',
          mentionAlignment: 'left',
          twitterShare: false,
          shareUrl: '',
          shareAlignment: 'left',
          tweet: false,
          tweetId: '',
          tweetAlignment: 'left',
        },
        layout: 'Standard',
        design: {},
      };

    case 'YouTube':
      return {
        content: {
          rawUrl: 'https://www.youtube.com/watch?v=PVGeM40dABA',
          videoId: null,
          listId: null,
        },
        layout: 'Standard',
        design: {},
      };

    case 'Livestream':
      return {
        content: {
          rawUrl: 'https://www.youtube.com/watch?v=PVGeM40dABA',
          iframeUrl:
            '<iframe width="560" height="315" src="https://www.youtube.com/embed/PVGeM40dABA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
          videoId: null,
          listId: null,
        },
        layout: 'Standard',
        design: {},
      };

    case 'VimeoVideo':
      return {
        content: {
          videoIdRawUrl: '',
          videoId: '',
        },
        layout: 'Standard',
        design: {},
      };

    case 'IFrame':
      return {
        content: {
          title: '',
          src: 'https://www.example.com',
        },
        layout: 'Standard',
        design: {
          enabled: false,
          height: '100vh',
        },
      };

    case 'Appointedd':
    case 'Ecwid':
    case 'Shopify':
    case 'Woocommerce':
      return {
        layout: 'Standard',
        content: {
          tabIndex: tab >= 0 ? tab : null,
        },
        design: {
          enabled: false,
        },
      };

    case 'WordPress':
      return {
        content: {
          apiKey: '',
          apiSource: '',
          apiType: 'wordpress',
          testConnection: 'success',
          displayCategories: false,
          showSpecificCategory: false,
          tabIndex: tab >= 0 ? tab : null,
          categories: {
            all: {
              id: 'all',
              name: 'All',
              slug: 'page',
              pages: {},
              count: null,
              pageCount: null,
            },
          },
          activeCategory: [],
        },
        layout: 'List',
        design: {
          enabled,
          titleColor: palette.textColor,
          textColor: palette.textColor,
          cardColor: palette.alternateTextColor,
        },
      };
    case 'PDFViewer':
      return {
        content: {
          title: '',
          url: '',
        },
        layout: 'Material',
        design: {
          enabled,
          buttonDesign,
        },
      };

    case 'File':
      return {
        content: {
          title: i18n.t('download file'),
          url: '',
        },
        layout: 'Material',
        design: {
          enabled,
          buttonDesign,
        },
      };
    case 'Media':
      return {
        content: {
          title: i18n.t('Media'),
          url: '',
          alignMedia: 'left',
          alignTitle: 'left',
        },
        layout: 'Media',
        design: {
          enabled,
          titleColor: palette.textColor,
          backgroundColor: palette.alternateTextColor,
        },
      };

    case 'Button':
      return {
        content: {
          label: i18n.t('Button'),
          action: {
            selectedOption: 'page',
            page: '/',
            auth: 'sign-up',
          },
        },
        layout: 'Material',
        design: {
          enabled,
          ...buttonDesign,
        },
      };

    case 'FacebookPage':
      return {
        content: {
          showFaces: true,
          showCover: true,
          showEvents: false,
          showMessages: false,
          showTimeline: true,
          showLargeHeader: true,
          pageId: 'MetaForDevelopers',
        },
        layout: 'Standard',
        design: {},
      };

    case 'InstagramFeed':
      return {
        content: {
          access_token: false,
          display: 'instagram',
          instagramShare: false,
          shareUrl: '',
        },
        layout: 'Standard',
        design: {
          enabled,
          width: {
            enabled: false,
            value: 100,
          },
        },
      };

    case 'Zoom':
      return {
        content: {
          apiKey: '',
          secretKey: '',
          pmi: '',
          zoomPassword: '',
          zoomLeaveUrl: 'https://www.example.com/',
        },
        layout: 'Standard',
        design: {},
      };

    case 'Header':
      return {
        content: {
          image: '',
          title: `<p>${i18n.t(
            'Click on the editor pane on the left to start typing the',
          )} ${i18n.t('Title')} ${i18n.t('you want to')} <strong>${i18n.t(
            'display',
          )}</strong>! ${i18n.t('and ensure you click on')} <strong>"${i18n.t(
            'Save',
          )} "</strong>"${i18n.t('once done!')} "</p>`,
          subtitle: `<p>${i18n.t(
            'Click on the editor pane on the left to start typing the',
          )} ${i18n.t('Subtitle')} ${i18n.t('you want to')} <strong>${i18n.t(
            'display',
          )}</strong>! ${i18n.t('and ensure you click on')} <strong>"${i18n.t(
            'Save',
          )} "</strong>"${i18n.t('once done!')} "</p>`,
          link: {
            enabled: true,
            linkType: {
              selectedOption: 'page',
              page: '/',
              web: 'https://www.example.com',
              phone: '+1-555-555-5555',
              email: 'support@example.com',
              auth: 'sign-up',
            },
            button: {
              enabled: true,
              label: i18n.t('Button'),
            },
          },
        },
        layout: 'Large',
        design: {
          enabled,
          buttonDesign,
          backgroundColor: palette.secondary1Color,
          gradientColor: {
            r: 0,
            g: 0,
            b: 0,
          },
          gradientOverlay: 20,
          textColor: palette.alternateTextColor,
          positionX: 50,
          positionY: 50,
        },
      };

    case 'Countdown':
      return {
        content: {
          date: Date.now() + 60 * 60 * 60 * 60,
          displayDays: true,
          displaySeconds: true,
          header: i18n.t('Thursday, 1st March'),
          subheader: `${i18n.t('Doors open at 17')}:00`,
          previewCard: false,
          card: {
            title: 'Lorem ipsum dolor sit amet',
            caption:
              'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
          },
          link: {
            enabled: true,
            linkType: {
              selectedOption: 'page',
              page: '/',
              web: 'https://www.example.com',
              phone: '+1-555-555-5555',
              email: 'support@example.com',
            },
            button: {
              enabled: true,
              label: i18n.t('Contact Me'),
            },
          },
        },
        layout: 'Standard',
        design: {
          enabled,
          buttonDesign,
          color: palette.alternateTextColor,
          backgroundColor: palette.secondary1Color,
          gradientColor: {
            r: 0,
            g: 0,
            b: 0,
          },
          gradientOverlay: 20,
          timerDesign: {
            enabled,
            color: palette.alternateTextColor,
            backgroundColor: palette.secondary1Color,
            labelColor: palette.alternateTextColor,
          },
          cardDesign: {
            enabled,
            color: palette.alternateTextColor,
            backgroundColor: palette.secondary1Color,
          },
        },
      };
    case 'Popup':
      return {
        layout: 'Standard',
        content: {
          showPopupAs: 'click',
          popup: '',
          buttonText: '',
        },
        design: {
          enabled,
          buttonDesign,
          color: palette.alternateTextColor,
          backgroundColor: palette.secondary1Color,
          gradientColor: {
            r: 0,
            g: 0,
            b: 0,
          },
          gradientOverlay: 20,
        },
      };
    case 'Image':
      return {
        content: {
          caption: i18n.t('Your caption goes here'),
          src: 'http://via.placeholder.com/350x150',
          link: {
            enabled: false,
            linkType: {
              selectedOption: 'page',
              page: '/',
            },
          },
        },
        layout: 'Standard',
        design: {
          enabled,
          width: {
            enabled: true,
            value: 100,
          },
        },
      };

    case 'Delegates':
      return {
        content: {
          items: {
            chunks: {
              '-Kth84tyu-2jUmw3RW7v': {
                avatar:
                  'https://s3.eu-west-2.amazonaws.com/beezer-img-storage/UploadImageForUser.png',
                subtitle: i18n.t('Subtitle'),
                text: i18n.t('Description'),
                title: i18n.t('Delegates Item 1'),
                twitter: 'https://www.twitter.com',
                linkedin: 'https://www.linkedin.com',
              },
            },
            order: ['-Kth84tyu-2jUmw3RW7v'],
          },
        },
        layout: 'Standard',
        design: {
          checkedIconColor: palette.primary1Color,
        },
      };

    case 'Loyalty':
      return {
        layout: 'Standard',
        content: {
          numberOfVisits: 10,
          freeItem: i18n.t('Coffee'),
        },
        design: {
          enabled,
          backgroundColor: palette.secondary1Color,
        },
      };

    case 'Food':
      return {
        layout: 'Standard',
        content: { specialItems: true },
        design: {
          backgroundColor: '#17141d',
          primaryColor: '#ffffff',
          secondaryColor: '#ff8a00',
          tertiaryColor: '#7a7a8c',
          checkoutColor: '#3f2573',
        },
      };

    case 'Marketplace':
      return {
        layout: 'Standard',
        content: {},
        design: {},
      };

    case 'FkAcademyGroups':
      return {
        layout: 'Standard',
        content: {},
        design: {},
      };

    case 'Forum':
      return {
        layout: 'Standard',
        content: {
          likes: false,
        },
        design: {
          enabled,
          backgroundColor: palette.secondary1Color,
        },
      };

    case 'Food':
      return {
        layout: 'Standard',
        content: { specialItems: true },
        design: {
          enabled,
          backgroundColor: palette.secondary1Color,
        },
      };

    case 'Food':
      return {
        layout: 'Standard',
        content: { specialItems: true },
        design: {
          enabled,
          backgroundColor: palette.secondary1Color,
        },
      };

    case 'Vote':
      return {
        content: {
          title: '',
          displayTitle: false,
          end: {
            enabled: false,
          },
          options: {
            chunks: {
              '-Kze25qxr-1yTiw5Ee1z': {
                avatar:
                  'https://beezer-img-storage.s3.amazonaws.com/images/97f0e81f-64c8-49f7-a44f-ba7db8d11cba.jpeg',
                subtitle: i18n.t('Subtitle'),
                title: i18n.t('Option 1'),
              },
              '-Klk02lkj-0Ukwl1Ee9y': {
                avatar:
                  'https://beezer-img-storage.s3.amazonaws.com/images/97f0e81f-64c8-49f7-a44f-ba7db8d11cba.jpeg',
                subtitle: i18n.t('Subtitle'),
                title: i18n.t('Option 2'),
              },
            },
            order: ['-Kze25qxr-1yTiw5Ee1z', '-Klk02lkj-0Ukwl1Ee9y'],
          },
          sendTo: account,
          start: {
            enabled: false,
          },
          type: 'Vote',
        },
        layout: 'Standard',
        design: {
          enabled,
          buttonDesign,
        },
      };

    case 'EventList':
      return {
        content: {
          type: 'EventList',
          eventList: '',
          filters: [],
        },
        layout: 'Standard',
        design: {},
      };

    case 'EventCourses':
      return {
        content: {
          type: 'EventCourses',
          EventCourses: '',
          filters: [],
        },
        layout: 'Standard',
        design: {},
      };

    case 'EventCalendar':
      return {
        content: {
          event: {
            buttonText: i18n.t('Submit'),
            fields: {
              chunks: {
                '-Kyn21hnw-1EklN8LA0z': {
                  defaultValue: '',
                  isGridVisible: true,
                  isPublic: true,
                  isRequired: true,
                  isSearchable: true,
                  label: i18n.t('Event Name'),
                  tpl: 'primary',
                  type: 'Text',
                },
                '-Kzn77wre-9lmOl8YQ8y': {
                  defaultValue: '',
                  icon: 'CalendarTodayRounded',
                  isGridVisible: true,
                  isPublic: true,
                  isRequired: true,
                  isSearchable: true,
                  label: i18n.t('Event Dates'),
                  type: 'DateRange',
                },
                // '-MBrzULMiJgRNRpuZzd3': {
                //   defaultValue: '',
                //   options: 'Type1, Type2, Type3',
                //   isGridVisible: false,
                //   isPublic: true,
                //   isRequired: false,
                //   isSearchable: false,
                //   label: 'Type',
                //   type: 'Dropdown',
                // },
                // '-MBrzWWA8j9hdpIPeuSf': {
                //   defaultValue: '',
                //   options: 'Category1, Category2',
                //   isGridVisible: false,
                //   isPublic: true,
                //   isRequired: false,
                //   isSearchable: true,
                //   label: 'Category',
                //   type: 'Dropdown',
                // },
                '-MBrz_L3rvyjMxCTeS4T': {
                  defaultValue: '',
                  isGridVisible: false,
                  isPublic: true,
                  isRequired: false,
                  isSearchable: false,
                  label: i18n.t('Description'),
                  tpl: 'content',
                  type: 'Text',
                },
                '-MBrzYYklyZYOZbzAyBQ': {
                  defaultValue: '',
                  isGridVisible: true,
                  isPublic: true,
                  isRequired: false,
                  isSearchable: true,
                  label: i18n.t('Location'),
                  tpl: 'secondary',
                  type: 'Text',
                },
                '-MBsA0V9u4eKHU8aRFDh': {
                  defaultValue: '',
                  icon: 'LocalPostOfficeRounded',
                  isGridVisible: true,
                  isPublic: true,
                  isRequired: true,
                  isSearchable: false,
                  label: i18n.t('Registration Email Address'),
                  subscriptionMail: true,
                  type: 'Email',
                },
                '-MEXsV8uPEPQrL5Cxqy0': {
                  defaultValue: '',
                  isGridVisible: false,
                  isPublic: true,
                  isRequired: false,
                  isSearchable: false,
                  label: i18n.t('Event Registration Url'),
                  subscription: true,
                  type: 'Text',
                },
                public: {
                  defaultValue: false,
                  icon: 'LocalPostOfficeRounded',
                  isGridVisible: false,
                  isPublic: false,
                  isRequired: false,
                  isSearchable: false,
                  label: i18n.t('Available for Public Users'),
                  type: 'Toggle',
                },
                status: {
                  defaultValue: true,
                  isGridVisible: true,
                  isPublic: false,
                  isRequired: false,
                  isSearchable: false,
                  label: i18n.t('Active'),
                  type: 'Toggle',
                },
              },
              order: [
                '-Kyn21hnw-1EklN8LA0z',
                '-Kzn77wre-9lmOl8YQ8y',
                '-MBrzULMiJgRNRpuZzd3',
                '-MBrzWWA8j9hdpIPeuSf',
                '-MBrz_L3rvyjMxCTeS4T',
                '-MBrzYYklyZYOZbzAyBQ',
                '-MBsA0V9u4eKHU8aRFDh',
                '-MEXsV8uPEPQrL5Cxqy0',
              ],
            },
          },
          enableCourses: true,
          course: {
            buttonText: i18n.t('Submit'),
            fields: {
              chunks: {
                '-MD_CV05ltz2ODzgA4gj': {
                  defaultValue: '',
                  isGridVisible: true,
                  isPublic: true,
                  isRequired: true,
                  isSearchable: true,
                  label: i18n.t('Course Name'),
                  tpl: 'primary',
                  type: 'Text',
                },
                '-MD_CWufswiN6riRhtJ1': {
                  defaultValue: '',
                  icon: 'CalendarTodayRounded',
                  isGridVisible: true,
                  isPublic: true,
                  isRequired: true,
                  isSearchable: true,
                  label: i18n.t('Course Dates'),
                  type: 'DateRange',
                },
                // '-MD_CYcT-KkQcVuIhfYo': {
                //   defaultValue: '',
                //   options: 'Type1, Type2',
                //   isGridVisible: false,
                //   isPublic: true,
                //   isRequired: false,
                //   isSearchable: true,
                //   label: 'Type',
                //   type: 'Dropdown',
                // },
                // '-MD_C_PCoK3C4vTYadPS': {
                //   defaultValue: '',
                //   options: 'Category1, Category2',
                //   isGridVisible: false,
                //   isPublic: true,
                //   isRequired: false,
                //   isSearchable: true,
                //   label: 'Category',
                //   type: 'Dropdown',
                // },
                '-MD_CbBgqj6ujod_rw2i': {
                  defaultValue: '',
                  isGridVisible: false,
                  isPublic: true,
                  isRequired: false,
                  isSearchable: false,
                  label: i18n.t('Description'),
                  tpl: 'content',
                  type: 'Text',
                },
                '-MD_CdK_0d4QnoHZhY77': {
                  defaultValue: '',
                  options: i18n.t('Location1, Location2'),
                  isGridVisible: true,
                  isPublic: true,
                  isRequired: false,
                  isSearchable: true,
                  label: i18n.t('Location'),
                  tpl: 'secondary',
                  type: 'Dropdown',
                },
                '-MD_Cf2cn07K_E0mwQSv': {
                  defaultValue: '',
                  icon: 'LocalPostOfficeRounded',
                  isGridVisible: true,
                  isPublic: true,
                  isRequired: true,
                  isSearchable: false,
                  label: i18n.t('Course Registration Email Address'),
                  subscriptionMail: true,
                  type: 'Email',
                },
                '-MEXzMQp6Ys-AYPO1_Hg': {
                  defaultValue: '',
                  isGridVisible: false,
                  isPublic: true,
                  isRequired: false,
                  isSearchable: false,
                  label: i18n.t('Course Registration Url'),
                  subscription: true,
                  type: 'Text',
                },
                public: {
                  defaultValue: false,
                  icon: 'LocalPostOfficeRounded',
                  isGridVisible: false,
                  isPublic: false,
                  isRequired: false,
                  isSearchable: false,
                  label: i18n.t('Available for Public Users'),
                  type: 'Toggle',
                },
                status: {
                  defaultValue: true,
                  isGridVisible: true,
                  isPublic: false,
                  isRequired: false,
                  isSearchable: false,
                  label: i18n.t('Active'),
                  type: 'Toggle',
                },
              },
              order: [
                '-MD_CV05ltz2ODzgA4gj',
                '-MD_CWufswiN6riRhtJ1',
                '-MD_CYcT-KkQcVuIhfYo',
                '-MD_C_PCoK3C4vTYadPS',
                '-MD_CbBgqj6ujod_rw2i',
                '-MD_CdK_0d4QnoHZhY77',
                '-MD_Cf2cn07K_E0mwQSv',
                '-MEXzMQp6Ys-AYPO1_Hg',
              ],
            },
          },
        },

        layout: 'Standard',
        design: {
          enabled,
          titleColor: palette.alternateTextColor,
          subTitleColor: palette.alternateTextColor,
          dateColor: palette.alternateTextColor,
        },
      };

    case 'Workforces':
      return {
        content: {
          type: 'Workforces',
          Workforces: '',
          filters: [],
        },
        layout: 'Standard',
        design: {},
      };

    case 'Notification':
      return {
        content: {
          type: 'Notification',
        },
        layout: 'Standard',
        design: {},
      };

    case 'Rating':
      return {
        content: {
          type: 'Rating',
        },
        layout: 'Material',
        design: {
          enabled,
          buttonDesign,
          textColor: palette.textColor,
          focusColor: palette.accent1Color,
        },
      };

    case 'PayNow':
      return {
        layout: 'Standard',
        content: {
          amount: 10,
          product: '',
        },
        design: {},
      };
    case 'Canva':
      return {
        content: {
          title: '',
          designId: '',
        },
        layout: 'Standard',
        design: {
          enabled: false,
          height: '100vh',
        },
      };
    case 'Gallery':
      return {
        content: {
          images: [],
          enableMainImage: true,
          mainImage: '',
          imageColumns: 3,
        },
        layout: 'HorizontalSlider',
        design: {
          enabled: true,
          backgroundColor: palette.primary1Color,
          borderColor: palette.alternateTextColor,
        },
      };
    case 'Appointment':
      return {
        layout: 'Standard',
        content: {},
        design: {
          enabled: true,
          backgroundColor: palette.primary1Color,
          borderColor: palette.alternateTextColor,
        },
      };

    case 'Feedback':
      return {
        layout: 'Standard',
        content: {},
        design: {},
      };
    case 'SubscriptionPlans':
      return {
        layout: 'Standard',
        content: {},
        design: {},
      };

    case 'Title':
      return {
        content: {
          editorContent: `<p>${i18n.t(
            'Click on the editor pane on the left to start typing the',
          )} ${i18n.t('Title')} ${i18n.t('you want to')} <strong>${i18n.t(
            'display',
          )}</strong>! ${i18n.t('and ensure you click on')} <strong>"${i18n.t(
            'Save',
          )} "</strong>"${i18n.t('once done!')} "</p>`,
          link: {
            selectedOption: 'page',
            page: '/',
            enabled: false,
          },
        },
        layout: 'Standard',
        design: {
          enabled,
          backgroundColor: '#bce3ff',
          borderColor: '#fff',
          borderWidth: 2,
          borderRadius: 10,
          marginTop: 10,
          marginBottom: 0,
        },
      };

    case 'Blog':
      return {
        content: {
          titleEnabled: false,
          titleText: '',
        },
        layout: 'Standard',
        design: {
          enabled,
          titleColor: palette.textColor,
          backgroundColor: '#fff',
          categoryTextColor: palette.textColor,
          categoryBackgroundColor: '#e0e0e0',
          categorySelectedTextColor: palette.textColor,
          categorySelectedBackgroundColor: palette.accent1Color,
          blogTitleColor: palette.textColor,
          buttonTextColor: '#fff',
          buttonBackgroundColor: palette.accent1Color,
        },
      };

    default:
      return {
        content: {
          type,
        },
        layout,
        design: {},
      };
  }
};

export default seedComponent;
