const specialAddonPrices = {
  // Html: {
  //   yearly_product_id: 'prod_Nlvm3zhiIuEvif',
  //   monthly_product_id: 'prod_NlvmqBry0gERai',
  //   title: 'Html Component',
  //   componentName: 'Html',
  //   price: 6,
  //   year_price: 72,
  // },
  PDFViewer: {
    yearly_product_id: 'prod_Nlvm4lzOWWkFTk',
    monthly_product_id: 'prod_NlvlgMsmKsjxSE',
    title: 'PDFViewer Component',
    componentName: 'PDFViewer',
    price: 4,
    year_price: 48,
  },
  Feedback: {
    yearly_product_id: 'prod_NlvmAYY7JOiByD',
    monthly_product_id: 'prod_NlvmUdAVQGx5iU',
    title: 'Feedback Component',
    componentName: 'Feedback',
    price: 8,
    year_price: 96,
  },
  Blog: {
    yearly_product_id: 'prod_R8DrFWYZFf7JLk',
    monthly_product_id: 'prod_R8DuBbnciBaHX3',
    title: 'Blog Component',
    componentName: 'Blog',
    price: 10,
    year_price: 120,
  },
};

export default specialAddonPrices;
