import React from 'react';
import PropTypes from 'prop-types';
import InfoOutline from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import themeStyles from '../../../../_export.scss';
import Sidebar, { SidebarItem } from '../../../../app/components/sidebar';
import {
  Push as PushIcon,
  History as HistoryIcon,
  Livestream as LivestreamIcon,
  Loyalty as LoyaltyIcon,
  Zoom as ZoomIcon,
  Forum as ForumIcon,
  FeedbackIcon,
  BlogIcon,
} from './../../components/icons';

import { ShareViaSms as ShareViaSmsIcon } from './../../../distribute/components/icons';

import ViewApp from '../../../../app/components/buttons/viewApp/';
import { PGMOL_APPS } from '../../../../config/constants';

const EngageMenu = ({
  match,
  location,
  integration,
  isPublished,
  isAppAdmin,
  isSubAdminWithMulipleApps,
}) => {
  const { t } = useTranslation();
  const baseHref = `/${match.params.appname}/engage`;
  const activeCheck = prefix =>
    location.pathname === `/${match.params.appname}/engage/${prefix}`;
  const openLink = link => {
    window.open(link, '_blank');
  };

  const infoIcon = pdf => (
    <Tooltip
      title={t('How to use')}
      onClick={() => openLink(pdf)}
      style={{ color: themeStyles.primaryColor }}
    >
      <InfoOutline fontSize="small" />
    </Tooltip>
  );
  return (
    <Sidebar animate>
      <ViewApp isPublished={isPublished} match={match} />
      <SidebarItem
        label={t('Push Notifications')}
        href={`${baseHref}/push`}
        active={activeCheck('push')}
        icon={<PushIcon />}
      />
      <SidebarItem
        label={t('Push Notification History')}
        href={`${baseHref}/history`}
        active={activeCheck('history')}
        icon={<HistoryIcon />}
      />
      <SidebarItem
        label={t('SMS')}
        href={`${baseHref}/sms`}
        active={activeCheck('sms')}
        icon={<ShareViaSmsIcon />}
        {...((isAppAdmin || isSubAdminWithMulipleApps) && {
          rightAction: infoIcon(
            'https://kb-articles.s3.eu-west-2.amazonaws.com/Engage+with+SMS+Notifications.pdf',
          ),
        })}
        {...((isAppAdmin || isSubAdminWithMulipleApps) && {
          displayRightAction: true,
        })}
      />
      {!integration && (
        <SidebarItem
          label={t('Livestream')}
          href={`${baseHref}/livestream`}
          active={activeCheck('livestream')}
          icon={<LivestreamIcon />}
        />
      )}
      {!integration && (
        <SidebarItem
          label={t('Loyalty & Attendance')}
          href={`${baseHref}/scan`}
          active={activeCheck('scan')}
          icon={<LoyaltyIcon />}
        />
      )}
      {!integration && (
        <SidebarItem
          label={t('Zoom')}
          href={`${baseHref}/zoom`}
          active={activeCheck('zoom')}
          icon={<ZoomIcon />}
        />
      )}

      {!integration && (
        <SidebarItem
          label="Blog"
          href={`${baseHref}/blog`}
          active={activeCheck('blog')}
          icon={<BlogIcon />}
        />
      )}
      {!integration && (
        <SidebarItem
          label={t('Forum')}
          href={`${baseHref}/forum`}
          active={activeCheck('forum')}
          icon={<ForumIcon />}
        />
      )}
      {!integration && (
        <SidebarItem
          label={t('Video Feedback')}
          href={`${baseHref}/feedback`}
          active={activeCheck('feedback')}
          icon={<FeedbackIcon />}
        />
      )}
      {/*
    <SidebarItem
      label='Schedule Notification'
      href={`/${match.params.appname}/engage/schedule`}
      active={location.pathname === `/${match.params.appname}/engage/schedule`}
      icon={
        <img
          width={24}
          height={24}
          src={ScheduleIcon}
          alt='Schedule Notification icon'
        />
      }
    /> */}
    </Sidebar>
  );
};

EngageMenu.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  integration: PropTypes.object,
  isPublished: PropTypes.bool,
  isAppAdmin: PropTypes.bool.isRequired,
  isSubAdminWithMulipleApps: PropTypes.bool.isRequired,
};

EngageMenu.defaultProps = {
  integration: null,
  isPublished: false,
};

export default EngageMenu;
