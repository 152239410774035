import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ColorPicker from '../../../../components/colorPicker';

class Design extends Component {
  static propTypes = {
    palette: PropTypes.object,
    onColorChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    palette: {},
  };

  handleColorChange = (color, prop) => {
    const { r, g, b, a } = color.rgb;

    this.props.onColorChange(`rgba(${r},${g},${b},${a})`, prop);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <ColorPicker
          label={t('Primary Color')}
          color={this.props.palette.primary1Color}
          onChange={color => this.handleColorChange(color, 'primary1Color')}
        />
        <ColorPicker
          label={t('Secondary Color')}
          color={this.props.palette.secondary1Color}
          onChange={color => this.handleColorChange(color, 'secondary1Color')}
        />
        <ColorPicker
          label={t('Accent Color')}
          color={this.props.palette.accent1Color}
          onChange={color => this.handleColorChange(color, 'accent1Color')}
        />
        <ColorPicker
          label={t('Text Color')}
          color={this.props.palette.textColor}
          onChange={color => this.handleColorChange(color, 'textColor')}
        />
        <ColorPicker
          label={t('Alternate Text Color')}
          color={this.props.palette.alternateTextColor}
          onChange={color =>
            this.handleColorChange(color, 'alternateTextColor')
          }
        />
        <ColorPicker
          label={t('Navigation Links Color')}
          color={this.props.palette.navigationLinkColor || 'rgb(0,0,0)'}
          onChange={color =>
            this.handleColorChange(color, 'navigationLinkColor')
          }
        />
        <ColorPicker
          label={t('Background Color')}
          color={this.props.palette.backgroundColor}
          onChange={color => this.handleColorChange(color, 'backgroundColor')}
        />
        <ColorPicker
          label={t('Tab Page Title Color')}
          color={this.props.palette.tabTitleColor}
          onChange={color => this.handleColorChange(color, 'tabTitleColor')}
        />
        {/* <ColorPicker
          label={t('Marketplace Text Color')}
          color={this.props.palette.marketplaceTextColor}
          onChange={color =>
            this.handleColorChange(color, 'marketplaceTextColor')
          }
        /> */}
      </div>
    );
  }
}

export default withTranslation()(Design);
