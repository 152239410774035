import i18n from 'i18next';
import firebase from '../../../../../../../firebase';
import { capitaliseString } from '../../../../../../../utils';
import {
  openDialog,
  dialogActionComplete,
  reportDialogError,
} from '../../../../../../../app/modules/dialog/actions';
import { getRemoveDialogText } from '../../remove/actions';

const addingFormField = (appname, id, value) => ({
  type: 'ADDING_VOTE_ITEM',
  appname,
  id,
  value,
});

const reportErr = (lazyDataId, err) => ({
  type: 'FORM_FIELD_ERROR',
  key: lazyDataId,
  err,
});

export const designChange = (appname, key, type, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/always_data/${key}/design`);

  dataRef.child(type).set(value);

  return {
    type: 'FORM_DESIGN_CHANGE',
    appname,
    key,
    designType: type,
    value,
  };
};

export const fieldChange = (appname, dataId, field, value) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}`);
  dataRef.child(field).set(value);

  firebase
    .database()
    .ref(`app_groups/${appname}/groups/default/viewFormEntries`)
    .update({ [dataId]: value });

  return {
    type: 'FORM_FIELD_CHANGE',
    appname,
    dataId,
    value,
  };
};

export const removeField = (appname, dataId, index, key) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}/fields`);

  dataRef
    .child('order')
    .transaction(items => [
      ...items.slice(0, index),
      ...items.slice(index + 1),
    ]);

  dataRef
    .child('chunks')
    .child(key)
    .remove();

  return {
    type: 'FORM_REMOVE_FIELD',
    appname,
    dataId,
    key,
    index,
  };
};

export const launchRemoveDialog = (
  appname,
  dataId,
  index,
  key,
  history,
) => dispatch => {
  const text = getRemoveDialogText('FIELD');

  const options = {
    buttonLabel: i18n.t('Delete'),
    title: `${i18n.t('Delete')} Field`,
    text,
  };

  return dispatch(openDialog(options))
    .then(val => {
      if (val.action === 'submit') {
        dispatch(removeField(appname, dataId, index, key, history));
      }
    })
    .then(() => dispatch(dialogActionComplete()))
    .catch(err => dispatch(reportDialogError(err)));
};

export const duplicateItem = (appname, dataId, lastIndex, data) => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}/fields`);

  const chunkRef = dataRef.child('chunks');
  const result = chunkRef.push();
  const key = result.key;

  chunkRef.child(`${key}`).set(data);

  dataRef
    .child('order')
    .child(lastIndex + 1)
    .set(`${key}`);

  return {
    type: 'FORM_DUPLICATE_FIELD',
    appname,
    dataId,
    key,
    data,
  };
};

export const addField = (appname, dataId, value) => dispatch => {
  const dataRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${dataId}/fields`);
  const key = dataRef.child('chunks').push({
    type: value,
    label: capitaliseString(value),
    required: true,
  }).key;

  dispatch(addingFormField(appname, dataId, value));

  return dataRef
    .child('order')
    .transaction(items => {
      if (!items) return [key];

      return [...items, key];
    })
    .then(data => data.snapshot.val())
    .catch(err => dispatch(reportErr(err)));
};

const findIndex = (array, element) => {
  if (element === null) {
    return array.length;
  }

  return array.indexOf(element);
};

const moveArray = (oldIndex, newIndex, arr) => {
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const rearrangeField = (
  appname,
  lazydata,
  id,
  siblingId,
) => dispatch => {
  const itemsRef = firebase
    .database()
    .ref(`apps/${appname}/draft/lazy_data/${lazydata}/fields/order`);

  itemsRef
    .transaction(array => {
      if (array === null) return null;

      const oIndex = findIndex(array, id);
      let nIndex = findIndex(array, siblingId);

      if (nIndex > oIndex) {
        nIndex -= 1;
      }

      return moveArray(oIndex, nIndex, array);
    })
    .then(
      dispatch({
        type: 'REARRANGE_FORM_FIELD',
        id,
        siblingId,
      }),
    );
};

const receiveGaTrackingId = gaTrackingId => ({
  type: 'RECEIVE_GA_TRACKING_ID',
  data: gaTrackingId,
});

export const loadGoogleAnalytics = appname => async dispatch => {
  const gaRef = firebase.database().ref(`apps/${appname}/gaTrackingId`);
  try {
    const snapshot = await gaRef.once('value');
    const gaTrackingId = await snapshot.val();
    return dispatch(receiveGaTrackingId(gaTrackingId));
  } catch (err) {
    console.error(err);
  }
};
